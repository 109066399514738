import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store/configureStore';
import { clearHeader } from 'actions/headerAction';
import Message from 'components/parts/message';

// スタイル
import * as style from 'styles/util';
import { Container, Row } from 'react-bootstrap';

// パーツコンポーネント
import Header from 'components/parts/header';

// ページ
import PriForms from 'components/pri/forms';
import AdmIndex from 'components/adm/index';
import Logout from 'components/logout';
import NotFound from 'components/404';

import { isAccountPage, isUserPage } from 'helpers';
import { Alert } from 'react-bootstrap';

import WithAuthRoutes from 'components/withAuthRoutes';
import { Auth } from 'aws-amplify';
import { consoleLogger } from 'helpers';

/**
 * ルーターコンポーネント
 */
class Router extends Component {
  constructor(...props) {
    super(...props);

    // URL変更時のアクション
    history.listen(async() =>  {
      // 画面再更新の強制
      this.forceUpdate();
    });


    // タブ切り替え時の動作（トークンリフレッシュ）
    let isTabActive;
    window.onfocus = async function () {
      if (!isTabActive) {
        isTabActive = true;
        if (Auth.user) {
          try {
            const cognitoUser = await Auth.currentAuthenticatedUser();
            const currentSession = await Auth.currentSession();
            cognitoUser.refreshSession(currentSession.refreshToken, () => {
              consoleLogger('token refresh');
            });
          } catch (e) {
            consoleLogger('already sign out', e);
            Auth.signOut().then(() => {
            });
          }
        }
      }
    };

    window.onblur = function () {
      isTabActive = false;
    };

  }

  render() {
    // 認証表示ができないページの確認
    // そもそも認証していなければ非表示
    // 管理者アカウントページで管理者アカウント権限を持っていなければ非表示
    // ユーザーページでユーザー権限を持っていなければ非表示
    if (
      (Auth.user && 
      ((isAccountPage() && (this.props.headerState && !this.props.headerState.isAccount))||
        (isUserPage() && (this.props.headerState && !this.props.headerState.isUser)))) ||
      (!Auth.user && (isAccountPage() || isUserPage()))
    ) {

      // それ以外は認証エラー
      return (
        <style.Wrapper>
          <Header />
          <Container fluid="on">
            <Row>
              <div className="col-12 pt-3 pb-3">
                <Message />
                {this.props.headerState && 
                  <Alert variant="danger" >この画面は利用できません。</Alert>
                }
              </div>
            </Row>
          </Container>
        </style.Wrapper>
      );
    }

    return (
      <div>
        <ConnectedRouter history={history}>
          <Switch>
            {/* 利用申請画面 */}
            <Route exact path="/pri/forms" component={PriForms} />
            {/* ログアウト後の一時画面 */}
            <Route exact path="/logout" component={Logout} />
            {/* ログイン前画面 */}
            <Route exact path="/" component={AdmIndex} />

            {/* 各種認証の必要な画面 */}
            <Route exact path='/start' component={WithAuthRoutes} />
            <Route exact path='/adm/forms' component={WithAuthRoutes} />
            <Route exact path='/adm/forms/:id' component={WithAuthRoutes} />
            <Route exact path='/adm/accounts' component={WithAuthRoutes} />
            <Route exact path='/adm/accounts/count' component={WithAuthRoutes} />
            <Route exact path='/adm/accounts/:id' component={WithAuthRoutes} />
            <Route exact path='/srv/users' component={WithAuthRoutes} />
            <Route exact path='/srv/users/count' component={WithAuthRoutes} />
            <Route exact path='/srv/users/:id' component={WithAuthRoutes} />

            {/* 404 */}
            <Route component={NotFound} />
          </Switch>
        </ConnectedRouter>
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { headerState: state.header };
};

export default connect(mapStateToProps, {clearHeader})(Router);
