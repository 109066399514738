import React, { Component } from 'react';
import { Table, ButtonToolbar, Button } from 'react-bootstrap';
import SearchForm from './searchForm';
import StatusInfoPopup from './statusInfoPopup';
import { Link } from 'react-router-dom';
import { history } from 'store/configureStore';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { setPageData } from 'actions/payloadAction';
import UnitLoading from 'components/parts/loading/unitLoading';
import axios from 'axios';
import { consoleLogger } from 'helpers';
import { connect } from 'react-redux';
import Message from 'components/parts/message';
import { withAuthenticator } from 'aws-amplify-react';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';

class AdmAccounts extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...args) {
    super(...args);

    // メッセージ初期化（ただし他画面の遷移からのものでメッセージを残す場合はクリアしない（アカウント削除時））
    const deletedAccount = history.location.state && history.location.state.keepMessage;
    if (!deletedAccount) {
      this.props.clearMessage();
    }

    this.cancelToken = axios.CancelToken.source();

    if (!this.props.pageData.admAccounts) {
      // ローディングスタート
      this.props.setLoading('unit');

      // initial local state
      this.state = {
        // page
        pagination: '',
        // data
        search_attr: 'email',
        search_str: '',
        list: [],
        loading: false,
      };

      const values = {
        search_attr: 'email',
        search_str: '',
      };

      this.getData(values, true, deletedAccount);
    } else {
      this.state = this.props.pageData.admAccounts;
      this.state.loading = false;

      // 管理者アカウント画面削除から帰ってきた場合の処理
      // 画面自体をリフレッシュすると数ページ読み込んでいた場合などに
      // 再読み込みが大変になるので対象データだけ抜き出す
      if (history.location.state && history.location.state.deleteAccount) {
        this.pullAccount(history.location.state.deleteAccount);
      }
    }
  }

  // 一覧から対象のアカウントのみ抜き出す
  pullAccount = (id) => {
    const list = this.state.list.filter((data) => {
      if (data.account.Username !== id) {
        return true;
      }
      return false;
    });

    // eslint-disable-next-line
    this.state.list = list; // この関数は初期マウント時のみ使用するためsetStateは使用しない
    this.props.setPageData('admAccounts', this.state);
  }

  submitData = (values) => {
    // 個別バリデーションを行うとボタン類が固まっていてエラーが見づらいのでここで実施する
    if (values.search_attr && values.search_str.length > 100) {
      this.props.setMessage('danger', '100文字以内で入力してください。');

      return false;
    }

    // @NOTE 検索時に前のデータが残るということがあり、念のためページネーションをオフ
    values.pagination = null;

    this.getData(values);
  }

  loadMore = () => {
    const values = {
      search_attr: this.state.search_attr,
      search_str: this.state.search_str,
      pagination: this.state.pagination
    };

    this.getData(values);
  }

  getData = async(values, initial = false, deletedAccount = false) => {

    if (!initial) {
      // 初回はローディングユニット出しているので不要
      this.setState({ loading: true });
    }

    const data = {
      search_attr: values.search_attr,
      search_str: values.search_str,
    };

    // ページネーションがある場合のみ続きのページを読み込む
    if (values.pagination) {
      data['pagination'] = this.state.pagination;
    }

    const url = '/auth/adm/accounts';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      params: data,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        this.setState({ loading: false });
        if (res.status === 200) {
          const setData = {
            list: [],
            pagination: '',
            search_attr: values.search_attr,
            search_str: values.search_str,
          };
          if ((!res.data.items || !res.data.items.length)  && !res.data.pagination) {
            // データなし
            if (values.pagination) {
              setData['list'] = this.state.list;
            } else if (!deletedAccount) {
              this.props.setMessage('info', '管理者アカウントは見つかりませんでした。');
            }
            this.setState(setData);

          } else {
            // ステートにセット

            let list = [];
            if (!values.pagination) {
              list = res.data.items;
            } else {
              // ページャーがある場合のみデータを繋げる
              list = this.state.list.concat(res.data.items);
            }
            setData['list'] = list;

            if (res.data.pagination && res.data.pagination.PaginationToken) {
              setData['pagination'] = res.data.pagination.PaginationToken;
            }

            this.setState(setData);

            // 管理者アカウント削除後の初期マウント時はメッセージ残す
            if (!deletedAccount) {
              this.props.clearMessage();
            }
          }
        } else {
          this.props.setMessage('danger', `管理者アカウント一覧の取得に失敗しました。\n${res.data}`);
          consoleLogger('StatusCode ' + url, res.data);
        }
        this.forceUpdate();
        this.props.setPageData('admAccounts', this.state);  // 一覧系は別ページ遷移でもデータを残す
        this.props.clearLoading('unit');
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        this.props.clearLoading('unit');
        this.setState({ loading: false });
        this.props.setPageData('admAccounts', this.state);  // 一覧系は別ページ遷移でもデータを残す

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.props.setMessage('danger', `管理者アカウント一覧の取得に失敗しました。\n${err}`);
        consoleLogger('Catch ' + url, err);
      });
  };

  render() {
    return (
      <div className="col-10 pt-3 pb-3">
        <UnitLoading />
        <SearchForm onSubmit={this.submitData} initial={this.state} loadingState={this.state.loading} />
        <Message />
        {this.state.list.length > 0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>名前</th>
              <th>Eメール</th>
              <th>外線直通電話番号</th>
              <th>管理者権限</th>
              <th>有効状態</th>
              <th>
                ステータス
                <StatusInfoPopup />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((data, index) => {
              const account = data.account;
              return (
                <tr key={ index }>
                  <td><Link to={'/adm/accounts/' + account.Username} >{account.Attributes.name}</Link></td>
                  <td>{account.Attributes.email}</td>
                  <td>{account.Attributes.phone_number}</td>
                  <td>{(account.Attributes.given_name && account.Attributes.given_name.match(/^1/)) ? '○' : ''}</td>
                  <td>{account.Enabled ? 'Enabled' : 'Disabled'}</td>
                  <td>{account.UserStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>}
        {this.state.pagination && <ButtonToolbar className="justify-content-center">
          {this.state.pagination && <Button disabled={this.state.loading} onClick={this.loadMore}>
            管理者アカウントをさらにロード
          </Button>}
        </ButtonToolbar>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { pageData: state.payload };
};

export default withAuthenticator(connect(mapStateToProps, { setPageData, setMessage, clearMessage, setLoading, clearLoading})(AdmAccounts));


