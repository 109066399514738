/* eslint-disable no-irregular-whitespace */
// @TODO ログ機能の表示コメントアウトで使用しない箇所があるのでeslintの未使用変数チェックを無効化している
/* eslint no-unused-vars: 0 */
import React, { Component } from 'react';
import styled from 'styled-components';
import * as style from 'styles/util';
import { Table, ButtonToolbar, Button, ButtonGroup, Modal } from 'react-bootstrap';
import { validType } from 'const';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { setPageData } from 'actions/payloadAction';
import { setBreadcrumb, clearBreadcrumb } from 'actions/breadcrumbAction';
import { connect } from 'react-redux';
import StatusInfoPopup from '../statusInfoPopup';
import Message from 'components/parts/message';
import ButtonLoading from 'components/parts/loading/buttonLoading';
import UnitLoading from 'components/parts/loading/unitLoading';
import axios from 'axios';
import { formatDate, consoleLogger, getTimestamp } from 'helpers';
import { withAuthenticator } from 'aws-amplify-react';
import client_config from 'client-config';
import * as Scroll from 'react-scroll';
import { Auth } from 'aws-amplify';

class SrvUsersDetail extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...props) {
    super(...props);

    // パンくずリストの変数初期化
    this.props.clearBreadcrumb();

    const {params} = this.props.match;

    // @TODO ログ機能 初期表示ログの取得
    // ログ情報を取得する（ダミー）
    var logContentList = this.loadLogContent();
    // スクローラ
    this.scroller = Scroll.animateScroll;
    this.logBox = React.createRef();        // ログボックスの位置
    this.logButtonRef = React.createRef();  // ログボタンの位置
    const logContent = logContentList.map((logContentData, index) =>
      <li key={index} className="list-unstyled">
        {logContentData.date}<br />{logContentData.message}
      </li>
    );

    // local state.
    this.state = {
      id: params.id,
      memberData: null,
      loading: false,
      logView: false,           // @TODO ログ機能 ステート
      logContent: logContent,   // @TODO ログ機能 ステート
      logDeleteDialog: false,   // @TODO ログ機能 ステート
    };

    this.cancelToken = axios.CancelToken.source();

    // メッセージ初期化
    this.props.clearMessage();

    // ローディングスタート
    this.props.setLoading('unit');

    this.refresh(true);
  }

  // @TODO ログ機能 ログダミーデータ
  loadLogContent() {
    return [
      { date: '2019/01/02 00:11:22', message: 'xxxxxxx xxxxxxx xxxxxx' },
      { date: '2019/01/02 11:22:33', message: 'yyyyy y y yyyyyy y yyyy' },
      { date: '2019/01/02 22:33:44', message: 'zzzz zzzzzz zzzzzzz zzz' },
    ];
  }

  /**
   * ログ表示切替
   */
  toggleLogDisp = () => {
    // @TODO ログ表示切替機能
    if (this.state.logView === false) {
      this.setState({ logView: true }); // set local state

      // ログの位置にスクロールする
      setTimeout(() => {
        this.scroller.scrollTo(this.logBox.current.offsetTop);
      }, 100);
    } else {
      this.setState({ logView: false }); // set local state

      // 非表示→再表示の再にスクロール位置が残ってスムーズスクロールにならないので微調整
      // react-scrollだとうまくスクロール位置が調整されないのでwindow.scrollToを使用
      setTimeout(() => {
        window.scrollTo(0, window.scrollY - 1);
      }, 100);
    }
  }

  /**
   * ログ削除ダイアログ表示
   */
  logDeleteDialogShow = () => {
    // @TODO ログ機能 ログ表示
    this.setState({ logDeleteDialog: true });
  }

  /**
   * ログ削除ダイアログ非表示
   */
  logDeleteDialogClose = () => {
    // @TODO ログ機能 ログ非表示
    this.setState({ logDeleteDialog: false });
  }

  /**
   * ログ削除
   */
  execLogDelete = () => {
    // @TODO ログ削除機能 ログ削除時の処理
    this.setState({ logContent: (<li className="list-unstyled">ログを削除しました。</li>) }); // set local state
    this.setState({ logDeleteDialog: false });
  }

  /**
   * リフレッシュ
   * 
   * @param boolean initial:初回のみ（mount時にsetStateすると怒られるので）
   * @param boolean rotate:くるくるを回すかどうか
   */
  refresh = async (initial = false, rotate = true) => {
    // ローディングスタート（ボタン）
    if (rotate) {
      this.props.setLoading('button', 'refreshUserDetail');
    }

    // 初期表示では実行しない
    if (!initial) {
      // リフレッシュ中は他ボタンもすべて無効化しておく
      this.setState({loading: true});
    }

    const url = '/auth/srv/users/' + this.state.id;

    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get',
      url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200 && res.data.item && res.data.item.users && res.data.item.profile) {
          // 詳細をセット
          this.setState({
            memberData: res.data.item
          });
          // パンくずリストの名前格納
          this.props.setBreadcrumb(this.state.id);
        } else {
          this.props.setMessage('danger', `ユーザーデータの取得に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('statuscode:' + url, res.data);
        } 
        this.props.clearLoading('button', 'refreshUserDetail');
        this.props.clearLoading('unit');
        this.setState({ loading: false });
      })
      .catch(err => {
        const res = err.response;

        this.props.clearLoading('button', 'refreshUserDetail');
        this.props.clearLoading('unit');
        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.props.setMessage('warning', 'ユーザーデータは存在しません。');
        } else {
          // エラー時、 ローディングクリア＋ログ出力
          this.props.setMessage('danger', `ユーザーデータの取得に失敗しました。再実行してください。\n${err}`);
          consoleLogger('catch:' + url, err);
        }
        this.setState({ loading: false });
      });
  };

  /**
   * 有効化/無効化処理
   */
  changeValid = async (valid) => {
    const url = '/auth/srv/users/' + this.state.id;

    const data = {
      enabled: valid ? '1' : '0'
    };
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'put',
      url: client_config['api_url'] + url,
      params: data,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    this.setState({ loading: true });

    return axios(requestData)
      .then(res => {
        consoleLogger('API PUT:' + url);
        consoleLogger(res);
        if (res.status === 200 && !res.data.errcode) {
          // 成功時
          // 再度データリフレッシュする
          let message = '';
          if (valid) {
            message = 'ユーザーを有効にしました。';
          } else {
            message = 'ユーザーを無効にしました。';
          }

          // 一覧データを保持している場合は一覧データも書き換える
          if (this.props.pageData.srvUsers && this.props.pageData.srvUsers.list.length) {
            const srvUsers = this.props.pageData.srvUsers;
            const email = this.state.memberData.users[0].user.Attributes.email;
            srvUsers.list.forEach((data, index) => {
              // 対象ユーザー
              if (data.user && data.user.Attributes && data.user.Attributes.email && data.user.Attributes.email === email) {
                if (valid) {
                  data.user.Enabled = true;
                } else {
                  data.user.Enabled = false;
                }

                srvUsers.list[index] = data;
              }
            });
            this.props.setPageData('srvUsers', srvUsers);
          }

          return this.refresh(false, false)
            .then(() => {
              this.props.setMessage('success', message);
            })
            .catch((err) => {
              // このタイミングで一リフレッシュエラーが出ることは想定していないがエラーはログに出す
              // その他のエラー
              if (err.constructor && err.constructor.name === 'Cancel') {
                return false;
              }

              consoleLogger('catch error: after put item.' + url, err);
              this.props.setMessage('success', message);
            });
        } else {
          // その他のエラー
          if (valid) {
            this.props.setMessage('danger', `ユーザーの有効化に失敗しました。再実行してください。\n${res.data}`);
          } else {
            this.props.setMessage('danger', `ユーザーの無効化に失敗しました。再実行してください。\n${res.data}`);
          }
          consoleLogger('other error:' + url, res.data.errcode);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const res = err.response;

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.setState({ loading: false });

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.props.setMessage('warning', '対象のユーザーが見つかりません。');
        } else {
          // その他のエラー
          consoleLogger('catch error:' + url, err);
          if (res) {
            consoleLogger('catch error:' + url, res.data.errcode);
          }
          if (valid) {
            this.props.setMessage('danger', `ユーザーの有効化に失敗しました。再実行してください。\n${err}`);
          } else {
            this.props.setMessage('danger', `ユーザーの無効化に失敗しました。再実行してください。\n${err}`);
          }
        }
      });
  }

  /**
   * 削除処理
   */
  execDelete = async () => {
    const url = '/auth/srv/users/' + this.state.id;
    const email = this.state.memberData.users[0].user.Attributes.email;

    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'delete',
      url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    this.setState({ loading: true });

    return axios(requestData)
      .then(res => {
        consoleLogger('API DELETE:' + url);
        consoleLogger(res);
        if (res.status === 200 && !res.data.errcode) {
          // 削除後は一覧へ遷移
          this.props.setMessage('success', 'ユーザーを削除しました。');
          this.props.history.push({
            pathname: '/srv/users',
            state: { 
              keepMessage: true,  // 一覧画面表示時にメッセージを残す
              deleteUser: email   // 一覧画面表示時に対象のユーザーを抜く
            }
          });
        } else {
          // その他のエラー
          this.props.setMessage('danger', `ユーザーの削除に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('other error:' + url, res.data.errcode);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const res = err.response;

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.setState({ loading: false });

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.props.setMessage('warning', '対象のユーザーが見つかりません。');
        } else {
          // その他のエラー
          consoleLogger('catch error:' + url, err);
          if (res) {
            consoleLogger('catch error:' + url, res.data.errcode);
          }
          this.props.setMessage('danger', `ユーザーの削除に失敗しました。再実行してください。\n${err}`);
        }
      });
  }

  render() {
    const memberData = this.state.memberData;
    const profile = (memberData) ? memberData.profile.profile : null;

    let updated = false;
    // 1件でも有効な物があれば無効化ボタンを表示
    var btnEnabled = false;
    if (memberData) {
      memberData.users.forEach(function( userVal ) {
        if (userVal.user.Enabled ) {
          btnEnabled = true;
        }
      });

      // サービスは作成日時昇順とする
      memberData.users.sort((a, b) => getTimestamp(a.user.UserCreateDate) > getTimestamp(b.user.UserCreateDate) ? 1 : -1);

      // 更新日時・サービスは更新日時昇順とする
      if (memberData.profile && memberData.profile.updated) {
        updated = Object.keys(memberData.profile.updated).map((key) => {
          return {
            id: key,
            time: memberData.profile.updated[key]
          };
        });
      }

      if (updated && updated.length) {
        updated.sort((a, b) => getTimestamp(a.time) > getTimestamp(b.time) ? 1 : -1);
      }
    }

    var childrenDisp = {
      id: false,
      age: false,
      birthday: false,
      gender: false,
    };

    if (profile && profile.products && profile.products.length) {
      // 製品ID昇順
      profile.products.sort((a, b) => a.id > b.id ? 1 : -1);
    }

    if (profile && profile.children && profile.children.length) {
      // ID昇順
      profile.children.sort((a, b) => a.id > b.id ? 1 : -1);
      profile.children.forEach(function( date ) {
        if ('id' in date && date.id) {
          childrenDisp.id = true;
        }
        if ('age' in date && date.age) {
          childrenDisp.age = true;
        }
        if ('birthday' in date && date.birthday) {
          childrenDisp.birthday = true;
        }
        if ('gender' in date && date.gender) {
          childrenDisp.gender = true;
        }
      });
    }

    return (
      <div className="col-10 pt-3 pb-3">
        <UnitLoading />
        <div className="overflow-hidden">
          {memberData &&
            <ButtonToolbar className="float-right" aria-label="Toolbar with Button groups">
              {btnEnabled ? (
                <TopButton disabled={this.state.loading} variant="primary" onClick={() => this.changeValid(false)} className="def-btn-long">
                  無効化
                </TopButton>
              ) : (
                <div>
                  <TopButton disabled={this.state.loading} variant="danger" onClick={this.execDelete} className="def-btn-long">
                    削除
                  </TopButton>
                  <TopButton disabled={this.state.loading} variant="primary" onClick={() => this.changeValid(true)} className="def-btn-long">
                    有効化
                  </TopButton>
                </div>
              )}
            </ButtonToolbar>}
        </div>
        <Message />
        {memberData &&
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan="2">
                  Cognito登録情報{memberData.users[0].user.Attributes.email && '（' + memberData.users[0].user.Attributes.email + '）' }
                  <style.ReloadButton disabled={this.state.loading} variant="link" onClick={() => this.refresh()} className="float-right">
                    <ButtonLoading buttonName="refreshUserDetail" />
                  </style.ReloadButton>
                </th>
              </tr>
            </thead>
            <StyledTbody>
              {memberData.users.map((data, idx) => {
                const user = data.user;
                const service = data.service;
                var service_type_list = {
                  'id': 'ID/PW共通',
                  'fed': 'IDPフェデレーション',
                };
                var service_name_list = [];
                service.forEach(function( val ) {
                  service_name_list.push(val.service_name);
                });
                var service_name = '';
                if (service_name_list.length > 0) {
                  service_name = '（' + service_name_list.join('、') + '）';
                }
                var service_type = '';
                if (0 in service) {
                  service_type = service[0].service_type;
                }

                return (
                  <tr key={'service_' + idx}><td>
                    <div>
                      <div>サービス</div>
                      <div>{service_type in service_type_list ? service_type_list[service_type] : '未確定' }{ service_name }</div>
                    </div>
                    <div>
                      <div>sub（ユーザー識別子）</div>
                      <div>{user.Attributes.sub}</div>
                    </div>
                    <div>
                      <div>有効状態</div>
                      <div>{validType[user.Enabled]}</div>
                    </div>
                    <div>
                      <div>
                        ステータス
                        <StatusInfoPopup />
                      </div>
                      <div>{user.UserStatus}</div>
                    </div>
                    <div>
                      <div>Cognitoユーザー名</div>
                      <div>{user.Username}</div>
                    </div>
                    <div>
                      <div>作成日時</div>
                      <div>{formatDate(user.UserCreateDate)}</div>
                    </div>
                    <div>
                      <div>最終更新日時</div>
                      <div>{formatDate(user.UserLastModifiedDate)}</div>
                    </div>
                  </td></tr>
                );
              })}
            </StyledTbody>
          </Table>}
        {memberData && profile &&
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan="2">
                  サービス登録情報{memberData.users[0].user.Attributes.email && '（' + memberData.users[0].user.Attributes.email + '）'}
                  <style.ReloadButton disabled={this.state.loading} variant="link" onClick={() => this.refresh()} className="float-right">
                    <ButtonLoading buttonName="refreshUserDetail" />
                  </style.ReloadButton>
                </th>
              </tr>
            </thead>
            <tbody>
              {profile.name && <tr>
                <TdWidth>氏名</TdWidth>
                <td>{[profile.name.family, profile.name.middle, profile.name.first].join('　').replace('　　', '　')}</td>
              </tr>}
              {profile.name_kana && <tr>
                <td>フリガナ</td>
                <td>{[profile.name_kana.family, profile.name_kana.middle, profile.name_kana.first].join('　').replace('　　', '　')}</td>
              </tr>}
              {profile.nick_name && <tr>
                <td>ニックネーム</td>
                <td>{profile.nick_name}</td>
              </tr>}
              {profile.phone_number && <tr>
                <td>電話番号</td>
                <td>{profile.phone_number}</td>
              </tr>}
              <tr>
                <TdWidth>自宅座標</TdWidth>
                <td>{profile.home_position}</td>
              </tr>
              {profile.postal_code && <tr>
                <td>郵便番号</td>
                <td>{profile.postal_code}</td>
              </tr>}
              {profile.country && <tr>
                <td>国</td>
                <td>{profile.country}</td>
              </tr>}
              <tr>
                <td>都道府県</td>
                <td>{profile.prefecture}</td>
              </tr>
              {profile.city && <tr>
                <td>住所(郡市区)</td>
                <td>{profile.city}</td>
              </tr>}
              {profile.street_address && <tr>
                <td>住所(通り名)</td>
                <td>{profile.street_address}</td>
              </tr>}
              {profile.address && <tr>
                <td>住所２(それ以降)</td>
                <td>{profile.address}</td>
              </tr>}
              {profile.building_name && <tr>
                <td>住所３(マンション名、部屋番号 等)</td>
                <td>{profile.building_name}</td>
              </tr>}
              {profile.company && <tr>
                <td>会社名</td>
                <td>{profile.company}</td>
              </tr>}
              {profile.department && <tr>
                <td>部署名</td>
                <td>{profile.department}</td>
              </tr>}
              {profile.products && <tr>
                <td>製品情報</td>
                <td>
                  <Table striped bordered hover>
                    <thead>
                      <tr className="bg-white">
                        <th>No</th>
                        <th>プロダクトコード</th>
                        <th>シリアルナンバー</th>
                        <th>マップ有効期限日時</th>
                      </tr>
                    </thead>
                    <tbody>
                      {profile.products.map((data) => {
                        return (
                          <tr key={'products-' + data.id}>
                            <td>{data.id}</td>
                            <td>{data.code}</td>
                            <td>{data.serial_number}</td>
                            <td>{formatDate(data.map_date)}</td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </td>
              </tr>}
              <tr>
                <td>本人の年齢</td>
                <td>{profile.age}</td>
              </tr>
              <tr>
                <td>本人の性別</td>
                <td>{profile.gender}</td>
              </tr>
              <tr>
                <td>年齢を記入した日</td>
                <td>{profile.age_date}</td>
              </tr>
              {profile.birthday && <tr>
                <td>本人の誕生日</td>
                <td>{profile.birthday}</td>
              </tr>}
              <tr>
                <td>配偶者の年齢</td>
                <td>{profile.partner_age}</td>
              </tr>
              <tr>
                <td>配偶者の性別</td>
                <td>{profile.partner_gender}</td>
              </tr>
              <tr>
                <td>年齢を記入した日</td>
                <td>{profile.partner_age_date}</td>
              </tr>
              {profile.partner_birthday && <tr>
                <td>配偶者の誕生日</td>
                <td>{profile.partner_birthday}</td>
              </tr>}
              <tr>
                <td>子供の人数</td>
                <td>{profile.children_number}</td>
              </tr>
              <tr>
                <td>子供の情報</td>
                <td>
                  <Table striped bordered hover>
                    <thead>
                      <tr className="bg-white">
                        {childrenDisp.id && <th>No</th>}
                        {childrenDisp.age && <th>年齢</th>}
                        <th>年齢を記入した日</th>
                        {childrenDisp.birthday && <th>誕生日</th>}
                        {childrenDisp.gender && <th>性別</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {profile.children.map((data) => {
                        return (
                          <tr key={'children-' + data.id}>
                            {childrenDisp.id && <td>{data.id}</td>}
                            {childrenDisp.age && <td>{data.age}</td>}
                            <td className="w30p">{data.age_date}</td>
                            {childrenDisp.birthday && <td className="w30p">{data.birthday ? data.birthday : ''}</td>}
                            {childrenDisp.gender && <td>{data.gender}</td>}
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                </td>
              </tr>
              {profile.car_categories && <tr>
                <td>所有車種カテゴリー</td>
                <td>{profile.car_categories}</td>
              </tr>}
              {profile.grandparents && <tr>
                <td>同居の祖父母</td>
                <td>{profile.grandparents === '1' ? 'いる' : 'いない'}</td>
              </tr>}
              {profile.LINEuserID && <tr>
                <td>LINEユーザーID</td>
                <td>{profile.LINEuserID}</td>
              </tr>}
              {(memberData.profile.created_at || memberData.profile.created_aud) && <tr>
                <td>作成日時・サービス</td>
                {/* @TODO 作成日時・更新日時はDB側がタイムゾーン記入のないJSTを保存しているためそのまま表示する DB側がタイムゾーン対応した場合下記のコメントアウトを外す */}
                <td>{memberData.profile.created_at}　{memberData.profile.created_aud in memberData.service_name_list ? memberData.service_name_list[memberData.profile.created_aud] : ''}</td>
                {/* <td>{formatDate(memberData.profile.created_at)}　{memberData.profile.created_aud in memberData.service_name_list ? memberData.service_name_list[memberData.profile.created_aud] : ''}</td> */}
              </tr>}
              {updated && <tr>
                <td>更新日時・サービス</td>
                <td>{updated.map((value, index) => {
                  return (
                    /* @TODO 作成日時・更新日時はDB側がタイムゾーン記入のないJSTを保存しているためそのまま表示する DB側がタイムゾーン対応した場合下記のコメントアウトを外す */
                    <div key={'updated-' + index}>{value.time}　{value.id in memberData.service_name_list ? memberData.service_name_list[value.id] : ''}</div>
                  );
                  // return (
                  //   <div key={'updated-' + index}>{formatDate(value.time)}　{value.id in memberData.service_name_list ? memberData.service_name_list[value.id] : ''}</div>
                  // );
                })}</td>
              </tr>}
            </tbody>
          </Table>}

        {/* @TODO @VIEW ログ機能 ログビュー部分 ここから */}
        {/* {memberData && <div className="text-center btn-block mb-3" aria-label="Toolbar with Button groups">
          <ButtonGroup className="mr-1" ref={this.logButtonRef}>
            {this.state.logView ? (
              <Button variant="primary" onClick={this.toggleLogDisp} className="def-btn-long">
                ログ非表示
              </Button>
            ) : (
              <Button variant="primary" onClick={this.toggleLogDisp} className="def-btn-long">
                  ログ表示
              </Button>
            )}
          </ButtonGroup>
          {this.state.logView && <ButtonGroup className="ml-1">
            <Button variant="danger" onClick={this.logDeleteDialogShow} className="def-btn-long">
              ログ削除
            </Button>
          </ButtonGroup>}
        </div>}
        <Modal show={this.state.logDeleteDialog} onHide={this.logDeleteDialogClose} centered>
          <Modal.Header closeButton>
            <Modal.Title>ログの削除</Modal.Title>
          </Modal.Header>
          <Modal.Body>ログを削除してよろしいですか？</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={this.logDeleteDialogClose} className="def-btn-long">
              Cancel
            </Button>
            <Button variant="primary" onClick={this.execLogDelete} className="def-btn-long">
              OK
            </Button>
          </Modal.Footer>
        </Modal>
        {this.state.logView && (
          <Logs ref={this.logBox}>
            {this.state.logContent}
          </Logs>
        )} */}
        {/* @TODO @VIEW  ログ機能 ログビュー部分 ここまで */}
      </div>
    );
  }
}

const TdWidth = styled.td`
    width: 220px;
`;

const StyledTbody = styled.tbody`
  td {
    padding: 0;
    display: flex;
    flex-flow: column;
    &>div {
      display: flex;

      &>div:first-child {
        flex: 0 0 220px;
        border-right: 1px solid #dee2e6;
      }

      &>div {
        padding: .1rem .75rem;
      }
    }

    &>div:first-child div {
      padding-top: .75rem;
    }
    &>div:last-child div {
      padding-bottom: .75rem;
    }

  }
`;

const TopButton = styled(Button)`
  margin-left: 10px;
  margin-bottom: 10px;
`;

const Logs = styled.ul`
  border: 1px solid rgba(0,0,0,.125);
  border-radius: .25rem;
  padding: 5px;
`;

const mapStateToProps = (state) => {
  return { pageData: state.payload };
};

export default withAuthenticator(connect(mapStateToProps, { setPageData, setMessage, clearMessage, setLoading, clearLoading, setBreadcrumb, clearBreadcrumb})(SrvUsersDetail));
