// アクション一覧
export const actions = {
  SET_MESSAGE: 'SET_MESSAGE', // グローバルメッセージセット
  CLEAR_MESSAGE: 'CLEAR_MESSAGE', // グローバルメッセージクリアー
  SET_BREADCRUMB: 'SET_BREADCRUMB', // パンくずリスト変数セット
  CLEAR_BREADCRUMB: 'CLEAR_BREADCRUMB', // パンくずリスト変数クリアー
  SET_BUTTON_LOADING: 'SET_BUTTON_LOADING', // ボタンローディングスタート
  CLEAR_BUTTON_LOADING: 'CLEAR_BUTTON_LOADING', // ボタンローディング終了
  SET_UNIT_LOADING: 'SET_UNIT_LOADING', // ユニットローディングスタート
  CLEAR_UNIT_LOADING: 'CLEAR_UNIT_LOADING', // ユニットローディング終了
  SET_PAGE_LOADING: 'SET_PAGE_LOADING', // 画面ローディングスタート
  CLEAR_PAGE_LOADING: 'CLEAR_PAGE_LOADING', // 画面ローディング終了
  SET_OVERLAY: 'SET_OVERLAY', // オーバーレイ表示
  CLEAR_OVERLAY: 'CLEAR_OVERLAY', // オーバーレイ非表示
  SET_HEADER: 'SET_HEADER', // ヘッダーセット
  CLEAR_HEADER: 'CLEAR_HEADER', // ヘッダークリアー
  SET_PAGEDATA: 'SET_PAGEDATA', // アプリケーション内保持ページデータ
};