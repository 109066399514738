import { actions } from 'actions';

const initalState = null;

// ローカルヘッダー用
// const initalState = {
//   isAccount: true,
//   isUser: true,
//   mail: 'sc_apn+unittest-local@sc-corp.co.jp'
// };

/**
 * ヘッダー
 */
const headerReducer = (state = initalState, action) => {
  switch (action.type) {
    // ヘッダーセット
    case actions.SET_HEADER:
      return {
        isAccount: action.isAccount,
        isUser: action.isUser,
        mail: action.mail
      };
    // ヘッダークリア
    case actions.CLEAR_HEADER:
      return null;
    default:
      return state;
  }
};

export default headerReducer;
