import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import InputText from 'components/parts/form/inputText';
import Select from 'components/parts/form/select';
import { Alert } from 'react-bootstrap';
import { accountSearchType } from 'const';
import * as style from 'styles/util';
import { connect } from 'react-redux';

const SearchForm = props => {
  // reduxのsubmittingだと「さらにロード」ボタンを無効化出来ないので自前のpropsを使用する
  const { handleSubmit, error, loadingState } = props;
  const options = accountSearchType;
  return (
    <style.SearchbarForm onSubmit={handleSubmit}>
      <Form.Group>
        <Field
          name="search_attr"
          component={Select}
          inline="on"
          validate={[]}
          className="align-top no-border-radius rounded-left col-3 search-type border-right-0"
          novalid="on"
          options={options}
        />
        <Field
          name="search_str"
          required="off"
          component={InputText}
          placeholder="検索ワードを入力"
          inline="on"
          validate={[]}
          className="align-top no-border-radius col-8 search-box"
          novalid="on"
        />
        <Button variant="primary" disabled={loadingState} type="submit" className="align-top no-border-radius rounded-right col-1">検索</Button>
      </Form.Group>
      {error && <Alert variant="danger" >{error}</Alert>}
    </style.SearchbarForm>
  );
};


const mapStateToProps = (state, props) => ({
  initialValues: { search_attr: props.initial.search_attr, search_str: props.initial.search_str },
});


export default connect(
  mapStateToProps
)(reduxForm({
  form: '_AccountListSearchForm',
})(SearchForm));
