import React, { Component } from 'react';
import { Form } from 'react-bootstrap';
import styled from 'styled-components';

class Check extends Component {
  render = () => {
    const { input, inline, id, className, label, type, feedback, novalid, meta: {touched, error} } = this.props;

    let styleName = '';
    if (className) {
      styleName += className;
    }

    if (novalid !== 'on' && touched && error) {
      styleName += ' is-invalid';
    } else if (novalid !== 'on' && touched && !error) {
      styleName += ' is-valid';
    }

    return (
      <StyledRadioWrap className="inline">
        <Form.Check inline={inline === 'on' && 'on'} feedback={feedback === 'on' && error} {...input} isInvalid={touched && error} id={id} type={type} className={styleName} label={label}/>
      </StyledRadioWrap>
    );
  }
}

const StyledRadioWrap = styled.div`
  &.inline {
    display: inline;
  }

  .valid-feedback, .invalid-feedback {
    margin-top: 0;
  }
`;

export default Check;