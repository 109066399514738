// 管理者アカウント管理画面のURL一覧
export const accountPageList = [
  '/adm/forms',
  '/adm/forms/:id',
  '/adm/accounts',
  '/adm/accounts/:id',
  '/adm/accounts/count'
];

// ユーザー管理画面のURL一覧
export const userPageList = [
  '/srv/users',
  '/srv/users/:id',
  '/srv/users/count'
];

// パンくずリスト一覧
// %sはグローバル変数うのパンくずリスト変数
export const breadcrumbList = {
  '/adm/forms': {
    '管理者アカウント': '/adm/accounts',
    '利用申請一覧': ''
  },
  '/adm/accounts/count': {
    '管理者アカウント': '/adm/accounts',
    '推定管理者アカウント数': ''
  },
  '/adm/accounts/:id': {
    '管理者アカウント': '/adm/accounts',
    '%s': ''
  },
  '/adm/accounts': {
    '管理者アカウント': '/adm/accounts',
    '一覧': ''
  },
  '/srv/users/count': {
    'ユーザー': '/srv/users',
    '推定ユーザー数': ''
  },
  '/srv/users/:id': {
    'ユーザー': '/srv/users',
    '%s': ''
  },
  '/srv/users': {
    'ユーザー': '/srv/users',
    '一覧': ''
  },
};

// 管理者アカウント検索のタイプ
export const accountSearchType = {
  'email': 'Eメール',
  'name': '名前',
  'phone_number': '外線直通電話番号',
  'preferred_username': '所属',
  'sub': 'sub（ユーザー識別子）'
};

// ユーザー検索のタイプ
export const userSearchType = {
  'email': 'Eメール',
  'sub': 'sub（ユーザー識別子）',
};

// 有効無効
export const validType = {
  false: 'Disabled',
  true: 'Enabled',
};

// サービスタイプ
export const serviceType = {
  '1': 'ブランドビジネス',
  '2': 'スマホビジネス',
  '3': '共通ID/PW',
};