import React from 'react';
import { history } from 'store/configureStore';
import { accountPageList, userPageList } from 'const';

/**
 * 現在が管理者アカウント関連画面の場合trueを返却
 */
export const isAccountPage = () => {
  const found = accountPageList.find((pageVal) => {
    const page = pageVal.replace(/:[0-9a-zA-Z_-]*/g, '[^/]*');
    const reg = new RegExp(page);
    if (history.location.pathname.match(reg)) {
      return true;
    }
    return false;
  });

  if (typeof found !== 'undefined') {
    return true;
  }

  return false;
};

/**
 * 現在がユーザー関連画面の場合trueを返却
 */
export const isUserPage = () => {
  const found = userPageList.find(function(pageVal) {
    const page = pageVal.replace(/:[0-9a-zA-Z_-]*/g, '[^/]*');
    const reg = new RegExp(page);
    if (history.location.pathname.match(reg)) {
      return true;
    }
    return false;
  });

  if (typeof found !== 'undefined') {
    return true;
  }

  return false;
};

/**
 * React用のnl2br
 */
export const nl2br = (text) => {
  if (!text) {
    return '';
  }
  const regex = /(\n)/g;
  return text.split(regex).map(function (line, i) {
    if (line.match(regex)) {
      return React.createElement('br', { key: i});
    }
    else {
      return line;
    }
  });
};

/**
 * front側表示する時間用にフォーマットする
 */
export const formatDate = (dateStr) => {

  // Edgeは日付と時間の間にTが入ってないのでパースでコケるので修正する
  dateStr = dateStr.replace(/([0-9]{4}[/-]{1}[0-9]{2}[/-]{1}[0-9]{2}) ([0-9]{2}:[0-9]{2}:[0-9]{2})/, '$1T$2');

  // DBの値はUTCなので正しくUTCを記入する
  if (!dateStr.match(/\+/)) {
    dateStr += '+00:00';
  }

  const d = new Date(dateStr);

  const result = d.getFullYear() + '/' +
    ('00' + (d.getMonth() + 1)).slice(-2) + '/' +
    ('00' + d.getDate()).slice(-2) + ' ' +
    ('00' + d.getHours()).slice(-2) + ':' +
    ('00' + d.getMinutes()).slice(-2) + ':' +
    ('00' + d.getSeconds()).slice(-2);

  return result;
};


/**
 * 開発用ロガー
 */
export const consoleLogger = (label = '', obj = '') => {
  if (process.env.NODE_ENV === 'development' && typeof console.log !== 'undefined') {
    console.log(label, obj);
  }
};

/**
 * sleep
 */
export const sleep = msec => new Promise(resolve => setTimeout(resolve, msec));

/**
 * timestampを返却（時刻の比較用にint値）計算する。単純な時刻比較だとブラウザによって誤動作をしたので変更
 * 
 */
export const getTimestamp = (dateStr = '') => {
  const timestamp = + new Date(dateStr);
  return timestamp;
};
