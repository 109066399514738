import React, { Component } from 'react';
import styled from 'styled-components';
import { Table, Modal, Button, Alert } from 'react-bootstrap';
import DecideModalForm from './decideModalForm';
import { formatDate, consoleLogger } from 'helpers';
import { nl2br } from 'helpers';

class DecideModal extends Component {

  render() {
    const data = this.props.data;

    if (!data.form && !data.error) {
      return false;
    }

    consoleLogger(data);

    if (data.error) {
      return (
        <Modal
          {...this.props}
          size="lg"
          aria-labelledby="decide-modal"
          centered
        >
          <Modal.Header closeButton className="col-12">
            <Modal.Title id="decide-modal" className="text-center">
              利用申請の決裁
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {data.error && <Alert variant={ data.error.type} >{data.error.message}</Alert>}
            <div className="text-center mt-4">
              <Button variant="primary" className="def-btn-more-long submit" onClick={ this.props.onHide }>利用申請一覧</Button>
            </div>
          </Modal.Body>
        </Modal>
      );
    }

    const formData = JSON.parse(data.form);

    return (
      <Modal
        {...this.props}
        size="lg"
        aria-labelledby="decide-modal"
        centered
      >
        <Modal.Header closeButton className="col-12">
          <Modal.Title id="decide-modal" className="text-center">
            利用申請の決裁
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <StyledTable striped bordered hover>
            <thead>
              <tr>
                <th colSpan="2">申請内容</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th>名前</th>
                <td>{formData.name}</td>
              </tr>
              <tr>
                <th>所属</th>
                <td>{formData.preferred_username}</td>
              </tr>
              <tr>
                <th>Eメール</th>
                <td>{formData.email}</td>
              </tr>
              <tr>
                <th>外線直通電話番号</th>
                <td>{formData.phone_number}</td>
              </tr>
              <tr>
                <th>申請利用権限</th>
                <td>{formData.srv_chk === '1' && 'ユーザー管理権限'}{formData.srv_chk === '1' && formData.adm_chk === '1' && '、'}{formData.adm_chk === '1' && '管理者アカウント管理権限'}</td>
              </tr>
              <tr>
                <th>申請理由</th>
                <td>{nl2br(formData.remarks)}</td>
              </tr>
              <tr>
                <th>申請日時</th>
                <td>{formatDate(data.created_at)}</td>
              </tr>
            </tbody>
          </StyledTable>

          <DecideModalForm data={data} onSubmit={this.props.role} onHide={this.props.modalClose}/>
        </Modal.Body>
      </Modal>
    );
  }
}


const StyledTable = styled(Table)`
  tbody th {
    border-top: none;
    border-bottom: none;
    border-left: none;
    min-width: 140px;
    padding: .5rem;
  }
  thead th {
    padding: .5rem;
  }
  tbody td {
    border: none;
    padding: .5rem;
  }
`;

export default DecideModal;
