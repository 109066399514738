import React, { Component } from 'react';
import * as style from 'styles/util';
import { Form } from 'react-bootstrap';

class TextArea extends Component {
  render = () => {
    const { input, className, label, placeholder, required, novalid, inline, meta: {touched, error} } = this.props;

    let styleName = '';
    if (className) {
      styleName += className;
    }

    if (novalid !== 'on' && touched && error) {
      styleName += ' is-invalid';
    } else if (novalid !== 'on' && touched && !error) {
      styleName += ' is-valid';
    }

    if (inline === 'on') {
      styleName += ' d-inline';
    }

    return (
      <Form.Group className={ inline ==='on' && 'd-inline' }>
        <Form.Label>
          {label}
          {required === 'on' && <style.Req>（必須）</style.Req>}
        </Form.Label>
        <Form.Control {...input} as="textarea" placeholder={placeholder} className={styleName}/>
        {touched && error && <style.ErrorMsg className="form-error">{error}</style.ErrorMsg>}
      </Form.Group>
    );
  }
}


export default TextArea;