import styled from 'styled-components';
import { Form , Button } from 'react-bootstrap';

// ページ全体のラッパー
export const Wrapper = styled.div`
  overflow-x: auto;
  min-height: 100vh;
  background-color: #F5F7F7;
  position: relative;
`;

// 固定ヘッダー無しのシンプルなページのヘッダー
export const SimpleHead = styled.h1`
  text-align: center;
  color: white;
  background-color: #232F3E;
  font-size: 20px;
  margin: 0 0 20px 0;
  height: 40px;
  line-height: 40px;
  a {
    color: white;
    text-decoration: none;
  }
`;


// 必須
export const Req = styled.span`
  color: #dc3545;
`;


// フォームエラーメッセージ
export const ErrorMsg = styled.span`
  color: #dc3545;
  &::before {
    content: '※';
  }
`;

// フォームエラーメッセージ（検索ワードのインライン部分用）
export const ErrorMsgInline = styled.span`
  margin: 5px 0;
  color: #dc3545;
  float: left;
  &::before {
    content: '※';
  }
`;


// 検索バー
export const SearchbarForm = styled(Form)`
  background-color: #fff;
  /* col-3だと広すぎてcol-2だと見切れるので */
  .search-type {
    flex: 0 0 250px;
    max-width: 250px;
  }
  .search-box {
    flex: 0 0 calc(91.666% - 250px);
    max-width: calc(91.666% - 250px);
  }
`;

// リロードボタン
export const ReloadButton = styled(Button)`
  color: #212529;
  padding: 0;
`;
