import React, { Component } from 'react';
import styled from 'styled-components';
import * as style from 'styles/util';
import { ButtonToolbar } from 'react-bootstrap';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { connect } from 'react-redux';
import Message from 'components/parts/message';
import ButtonLoading from 'components/parts/loading/buttonLoading';
import UnitLoading from 'components/parts/loading/unitLoading';
import axios from 'axios';
import { consoleLogger } from 'helpers';
import { withAuthenticator } from 'aws-amplify-react';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';


class AdmAccountsCount extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...props) {
    super(...props);

    // local state.
    this.state = {
      num: '',
    };

    this.cancelToken = axios.CancelToken.source();

    // メッセージ初期化
    this.props.clearMessage();

    // ローディングスタート
    this.props.setLoading('unit');

    this.refresh();
  }

  /**
   * 管理者アカウント数のリフレッシュ
   */
  refresh = async() => {
    // ローディングスタート（ボタン）
    this.props.setLoading('button', 'refreshAccount');
    const url = '/auth/adm/accounts/count';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200 && res.data.item && res.data.item.userpool && res.data.item.userpool.EstimatedNumberOfUsers) {
          // 管理者アカウント数セット
          this.setState({
            num: res.data.item.userpool.EstimatedNumberOfUsers
          });
          this.props.clearMessage();
        } else {
          this.props.setMessage('danger', `推定管理者アカウント数の取得に失敗しました。\n${res.data}`);
          consoleLogger('statuscode:' + url, res.data);
        }
        this.props.clearLoading('button', 'refreshAccount');
        this.props.clearLoading('unit');
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        this.props.clearLoading('button', 'refreshAccount');
        this.props.clearLoading('unit');

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        const res = err.response;

        // その他のエラー
        consoleLogger('catch error:' + url, err);
        if (res) {
          consoleLogger('catch error:' + url, res.data.errcode);
        }
        this.props.setMessage('danger', `推定管理者アカウント数の取得に失敗しました。\n${err}`);
      });
  };

  render() {
    const formatter = new Intl.NumberFormat('ja-JP');
    return (
      <div className="col-4 pt-3">
        <UnitLoading />
        <ButtonToolbar className="flex-right justify-content-end">
          <style.ReloadButton variant="link" onClick={this.refresh}>
            <ButtonLoading buttonName="refreshAccount" />
          </style.ReloadButton>
        </ButtonToolbar>
        <Message />
        <Dl className="adm-count">
          <Dt>推定管理者アカウント数</Dt>
          <Dd>{ formatter.format(this.state.num) }</Dd>
        </Dl>
      </div>
    );
  }
}

const Dl = styled.dl`
  width: 100%;
  display: table;
`;

const Dt = styled.dt`
  width: 220px;
  display: table-cell;
  padding: .75rem;
  border: 1px solid #dee2e6;
  border-right: 0 none;
`;

const Dd = styled.dd`
  display: table-cell;
  margin-left: 220px;
  margin-bottom: 0;
  padding: .75rem;
  border: 1px solid #dee2e6;
  text-align: right;
`;

const mapStateToProps = () => {
  return {};
};

export default withAuthenticator(connect(mapStateToProps, {setMessage, clearMessage, setLoading, clearLoading})(AdmAccountsCount));
