import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withAuthenticator } from 'aws-amplify-react';
import Message from 'components/parts/message';

class Start extends Component {

  render() {
    return (
      <div className="col-10 pt-3 pb-3">
        <Message />
      </div>
    );
  }
}


const mapStateToProps = () => {
  return {};
};

export default withAuthenticator(connect(mapStateToProps, {})(Start));
