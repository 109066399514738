import React, { Component } from 'react';
import styled from 'styled-components';
import { ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { history } from 'store/configureStore';
import { isAccountPage, isUserPage } from 'helpers';

class SidebarLink extends Component {
  getClass() {
    let style = 'list-group-item list-group-item-action';
    if (history.location.pathname.match(this.props.to)) {
      if (!this.props.exclude) {
        style += ' active';
      } else if (this.props.exclude && history.location.pathname !== this.props.exclude) {
        // 特定のURLのみ除外
        style += ' active';
      }
    }

    return style;
  }

  render() {
    return (
      <Link to={this.props.to} className={this.getClass()}>{this.props.title}</Link>
    );
  }
}

class AutherdSidebar extends Component {

  render() {
    if (isAccountPage()) {
      return (
        <StyledSidebar className="col-2 pt-3">
          <SidebarLink to="/adm/forms" title="利用申請一覧" />
          <SidebarLink to="/adm/accounts" exclude="/adm/accounts/count" title="管理者アカウント一覧" />
          <SidebarLink to="/adm/accounts/count" title="推定管理者アカウント数" />
        </StyledSidebar>
      );
    } else if (isUserPage()) {
      return (
        <StyledSidebar className="col-2 pt-3">
          <SidebarLink to="/srv/users" exclude="/srv/users/count" title="ユーザー一覧" />
          <SidebarLink to="/srv/users/count" title="推定ユーザー数" />
        </StyledSidebar>
      );
    } 

    return '';
  }
}

const StyledSidebar = styled(ListGroup)`
  background-color: #F5F7F7;
  min-height: calc(100vh - 90px);
`;


export default AutherdSidebar;
