import React, { Component } from 'react';
import * as style from 'styles/util';
import { Form } from 'react-bootstrap';

class Select extends Component {
  render = () => {
    const { input, className, label, inline, required, novalid, options, meta: {touched, error} } = this.props;

    let styleName = '';
    if (className) {
      styleName += className;
    }

    if (novalid !== 'on' && touched && error) {
      styleName += ' is-invalid';
    } else if (novalid !== 'on' && touched && !error) {
      styleName += ' is-valid';
    }

    if (inline === 'on') {
      styleName += ' d-inline';
    }

    return (
      <Form.Group className={inline === 'on' && 'd-inline'}>
        <Form.Label>
          {label}
          {required === 'on' && <style.Req>（必須）</style.Req>}
        </Form.Label>
        <Form.Control default='2' {...input} as="select" className={styleName} inline={inline === 'on' && 'on'}>
          {Object.keys(options).map((key) => {
            return (<option value={key} key={key}>{options[key]}</option>);
          })}
        </Form.Control>
        {touched && error && <style.ErrorMsg>{error}</style.ErrorMsg>}
      </Form.Group>
    );
  }
}


export default Select;