import React, { Component } from 'react';
import * as style from 'styles/util';
import { Table, ButtonToolbar, Button } from 'react-bootstrap';
import { SubmissionError } from 'redux-form';
import DecideModal from './decideModal';
import { history } from 'store/configureStore';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { connect } from 'react-redux';
import Message from 'components/parts/message';
import axios from 'axios';
import { formatDate, consoleLogger } from 'helpers';
import ButtonLoading from 'components/parts/loading/buttonLoading';
import UnitLoading from 'components/parts/loading/unitLoading';
import { withAuthenticator } from 'aws-amplify-react';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';

class AdmForms extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...args) {
    super(...args);

    // local state.
    this.state = {
      list: [],
      modalData: {},
      submitting: false,
    };

    this.cancelToken = axios.CancelToken.source();

    // メッセージ初期化
    this.props.clearMessage();

    // ローディングスタート
    this.props.setLoading('unit');

    // 初期データ取得
    this.refresh().then(() => {
      // URLにユーザIDを持っていた場合uuid4チェックしてモーダルを表示する
      let formId = null;
      if (this.props.match.params.id && this.props.match.params.id.match(/^[-a-z0-9]+$/)) {
        formId = this.props.match.params.id;
        this.modalOpen(formId);
      }
    });
  }


  submitData = async (values) => {
    if (values.form_id && (typeof values.type !== 'undefined' && (values.type === '1' || values.type === '2'))) {
      if (values.type === '1' && (!values.srv_chk && !values.adm_chk)) {
        // エラー時（仮）
        throw new SubmissionError({ _error: '権限設定してください。' });
      } else {

        const url = '/auth/adm/forms/' + values.form_id;
        const data = {
          exec_mode: values.type,
          srv_chk: (values.type === '1' && values.srv_chk) ? '1' : '0',
          adm_chk: (values.type === '1' && values.adm_chk) ? '1' : '0',
        };

        const currentSession = await Auth.currentSession();
        const requestData = {
          method: 'put',
          url: client_config['api_url'] + url,
          params: data,
          headers: { 'Authorization': currentSession.idToken.jwtToken },
          cancelToken: this.cancelToken.token
        };

        this.setState({ submitting: true });

        return axios(requestData)
          .then(res => {
            consoleLogger('API POST:' + url);
            consoleLogger(res);
            if (res.status === 200 && !res.data.errcode) {
              // 成功時
              // 再度一覧をロードした後モーダルをクローズする
              let message = '';
              if (values.type === '1') {
                message = '申請を承認しました。';
              } else if (values.type === '2') {
                message = '申請を却下しました。';
              }
              return this.refresh(false)
                .then(() => {
                  this.setState({ submitting: false });
                  this.modalClose();
                  this.props.setMessage('success', message);
                })
                .catch((err) => {
                  // このタイミングで一覧取得でエラーが出ることは想定していないがエラーはログに出す
                  // その他のエラー
                  if (err.constructor && err.constructor.name === 'Cancel') {
                    return false;
                  }

                  consoleLogger('catch error: after put item.' + url, err);
                  this.setState({ submitting: false });
                  this.modalClose();
                  this.props.setMessage('success', message);
                });
            } else {
              // その他のエラー
              this.setState({ submitting: false });
              consoleLogger('other error:' + url, res.data.errcode);
              throw new SubmissionError({ _error: `決裁に失敗しました。\n${res.data}` });
            }
          })
          .catch((err) => {
            const res = err.response;
            this.setState({ submitting: false });

            if (err.constructor && err.constructor.name === 'Cancel') {
              return false;
            }

            if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
              this.setState({
                submitting: false,
                modalData: {
                  error: {
                    type: 'warning',
                    message: '申請データが取得できませんでした。既に決裁済です。'
                  }
                },
              });
            } else {
              // その他のエラー
              consoleLogger('catch error:' + url, err);
              if (res) {
                consoleLogger('catch error:' + url, res.data.errcode);
              }
              throw new SubmissionError({ _error: `決裁に失敗しました。\n${err}` });
            }
          });
      }
    } else {
      // 処理が未選択時
      throw new SubmissionError({ _error: '処理を選択してください。' });
    }
  }

  modalClose = () => {
    if (!this.state.submitting) {
      this.setState({
        modalData: {}
      });
      history.replace('/adm/forms/');
    }
  };

  modalOpen = async (formId) => {
    const url = '/auth/adm/forms/' + formId;

    // ローディングスタート
    this.props.setLoading('overlay');

    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200) {
          // オーバーレイ非表示
          this.props.clearLoading('overlay');

          if (!res.data.item) {
            this.props.setMessage('danger', `申請データの詳細の取得に失敗しました。\n${res.data}`);
            consoleLogger('StatusCode ' + url, res.data);
          } else {
            // ステートにセット
            this.setState({
              modalData: res.data.item,
            });
            history.replace('/adm/forms/' + formId);
            this.props.clearMessage();
          }
        } else {
          this.props.setMessage('danger', `申請データの詳細の取得に失敗しました。\n${res.data}`);
          consoleLogger('StatusCode ' + url, res.data);
        }
      })
      .catch(err => {
        const res = err.response;
        // オーバーレイ非表示
        this.props.clearLoading('overlay');

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.setState({
            modalData: {
              error: {
                type: 'warning',
                message: '申請データが取得できませんでした。既に決裁済です。'
              }
            },
          });
        } else {
          this.setState({
            modalData: {
              error: {
                type: 'danger',
                message: `申請データの詳細の取得に失敗しました。\n${err}`
              }
            },
          });
          consoleLogger('Catch ' + url, err);
        }
      });
  };

  refresh = async (load = true) => {
    // 再度情報を取得する
    if (load) {
      // ローディングをする場合のみくるくる回す
      this.props.setLoading('button', 'refreshForms');
    }
    const url = '/auth/adm/forms';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200) {
          if (!res.data.items || !res.data.items.length) {
            this.props.setMessage('info', '利用申請はありません。');
            this.setState({
              list: [],
            });
          } else {
            // ステートにセット
            this.setState({
              list: res.data.items,
            });
            this.props.clearMessage();
          }
        } else {
          this.props.setMessage('danger', `申請データの取得に失敗しました。\n${res.data}`);
          consoleLogger('StatusCode ' + url, res.data);
        }
        this.props.clearLoading('button', 'refreshForms');
        this.props.clearLoading('unit');
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        this.props.clearLoading('button', 'refreshForms');
        this.props.clearLoading('unit');
        // オーバーレイ非表示
        this.props.clearLoading('overlay');

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.props.setMessage('danger', `申請データの取得に失敗しました。\n${err}`);
        consoleLogger('Catch ' + url, err);
      });
  };

  render() {
    return (
      <div className="col-10 pt-3 pb-3">
        <UnitLoading />
        <ButtonToolbar className="flex-right justify-content-end">
          <style.ReloadButton variant="link" onClick={this.refresh}>
            <ButtonLoading buttonName="refreshForms" />
          </style.ReloadButton>
        </ButtonToolbar>
        <Message />
        {this.state.list.length > 0 && <Table striped bordered hover className="forms-table">
          <thead>
            <tr>
              <th>名前</th>
              <th>所属</th>
              <th>Eメール</th>
              <th>外線直通電話番号</th>
              <th>申請日時</th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((data) => {
              if (!data.form_id || !data.form || !data.created_at) {
                return false;
              }
              const formData = JSON.parse(data.form);
              return (
                <tr key={data.form_id}>
                  <td><Button className="p-0" variant="link" onClick={() => this.modalOpen(data.form_id)}>{formData.name}</Button></td>
                  <td>{formData.preferred_username}</td>
                  <td>{formData.email}</td>
                  <td>{formData.phone_number}</td>
                  <td>{ formatDate(data.created_at) }</td>
                </tr>
              );
            })}
          </tbody>
        </Table>}
        <DecideModal
          show={Object.keys(this.state.modalData).length !== 0}
          data={this.state.modalData}
          onHide={this.modalClose}
          role={this.submitData}
        />
      </div>
    );
  }
}

const mapStateToProps = () => {
  return {};
};

export default withAuthenticator(connect(mapStateToProps, { setMessage, clearMessage, setLoading, clearLoading })(AdmForms));
