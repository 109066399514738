import { combineReducers } from 'redux';
import { reducer as reduxFormReducer } from 'redux-form';
import { connectRouter, LOCATION_CHANGE } from 'connected-react-router';

/* user reducer */
import messageReducer from 'reducers/messageReducer';
import breadcrumbReducer from 'reducers/breadcrumbReducer';
import loadingReducer from 'reducers/loadingReducer';
import headerReducer from 'reducers/headerReducer';
import payloadReducer from 'reducers/payloadReducer';

const defaultReducer = (state = {}, action) => {
  switch (action.type) {
    case LOCATION_CHANGE:
      return Object.assign({}, state, {});
    default:
      return state;
  }
};

export default (history) => combineReducers({
  router: connectRouter(history), // Router の Reducer名は 'router' で決まっている
  form: reduxFormReducer, // redux-form用のreducer
  defaultReducer,
  // 以下ユーザーストア要素
  message: messageReducer,        // グローバルメッセージ
  breadcrumb: breadcrumbReducer,  // グローバルパンくずリスト情報
  loading: loadingReducer,        // グローバルローディングアイコン
  header: headerReducer,          // グローバルヘッダー
  payload: payloadReducer,        // 各種ページコンテンツ
});
