import { actions } from 'actions';

const initalState = [
  {
    messages: []
  }
];

/**
 * グローバルメッセージリデューサー
 */
const messageReducer = (state = initalState, action) => {
  switch (action.type) {
    // メッセージセット
    case actions.SET_MESSAGE:
      return {
        ...state,
        messages: [{type: action.message_type, text: action.message_text}]
      };
    // メッセージクリアー
    case actions.CLEAR_MESSAGE:
      return {
        ...state,
        messages: []
      };
    default:
      return state;
  }
};

export default messageReducer;
