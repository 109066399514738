import client_config from 'client-config';

export const GetAmplifyConfig = (client_id) => ({

  // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
  //identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',
  // identityPoolId: 'ap-northeast-1_ez73UklrY',
  
  // REQUIRED - Amazon Cognito Region
  region: client_config['region'],

  // OPTIONAL - Amazon Cognito Federated Identity Pool Region 
  // Required only if it's different from Amazon Cognito Region
  //identityPoolRegion: 'XX-XXXX-X',
  // identityPoolRegion: 'ap-northeast-1',

  // OPTIONAL - Amazon Cognito User Pool ID
  userPoolId: client_config['UserPoolId'],

  // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
  userPoolWebClientId: client_id,

  // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
  mandatorySignIn: false,

  // OPTIONAL - Configuration for cookie storage
  // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
  //  cookieStorage: {
  // REQUIRED - Cookie domain (only required if cookieStorage is provided)
  //      domain: '.auth.ap-northeast-1.amazoncognito.com',
  // OPTIONAL - Cookie path
  //      path: '/',
  // OPTIONAL - Cookie expiration in days
  //      expires: 365,
  // OPTIONAL - Cookie secure flag
  // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
  //      secure: true
  //  },

  // OPTIONAL - customized storage object
  //storage: new MyStorage(),
    
  // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
  //authenticationFlowType: 'USER_PASSWORD_AUTH'
});
