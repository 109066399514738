import React, { Component } from 'react';
import { connect } from 'react-redux';
import FA from 'react-fontawesome';

/**
 * ボタンローディングコンポーネント
 */
class globalLoading extends Component {

  // ローディングがセットされていれば表示する
  render() {
    return (
      <span>
        <FA name="refresh" className={this.props.loadingState.buttonLoading && this.props.loadingState.buttonLoading[this.props.buttonName] ? 'loading' : ''} />
      </span>
    );
  }
}

const mapStateToProps = state => {
  return { loadingState: state.loading };
};

export default connect(mapStateToProps)(globalLoading);

