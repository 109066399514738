import React, { Component } from 'react';
import { Route, Switch } from 'react-router';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store/configureStore';
import AuthedSidebar from './authedSidebar';
import { accountPageList, userPageList } from 'const';

class Sidebar extends Component {
  render() {
    var routes = [];
    var ii;
    for (ii in accountPageList) {
      routes.push(<Route exact key={accountPageList[ii]} path={accountPageList[ii]} component={AuthedSidebar} />);
    }
    for (ii in userPageList) {
      routes.push(<Route exact key={userPageList[ii]} path={userPageList[ii]} component={AuthedSidebar} />);
    }
    return (
      <ConnectedRouter history={history}>
        <Switch>
          {/* 管理者アカウント系画面かユーザー系画面であれば管理者アカウントのサイドバーを表示 */}
          {routes}
        </Switch>
      </ConnectedRouter>
    );
  }
}

export default Sidebar;
