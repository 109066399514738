import { actions } from 'actions';

// ローカルヘッダー用
const initalState = {
};

/**
 * ページコンテンツ用
 */
const payloadReducer = (state = initalState, action) => {
  let pageData = {};
  switch (action.type) {
    // ページデータのセット
    case actions.SET_PAGEDATA:
      pageData = {
        [action.pageName]: action.pageData
      };
      return Object.assign(state, pageData);
    default:
      return state;
  }
};

export default payloadReducer;
