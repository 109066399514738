import React from 'react';
import { ListGroup } from 'react-bootstrap';
import * as style from 'styles/util';
import { Link } from 'react-router-dom';

const AdmIndex = () => {
  return (
    <div className="col-12 pl-0 pr-0 bg-gray">
      <style.SimpleHead>認証基盤管理システム</style.SimpleHead>
      <div className="col-8 col-lg-6 mx-auto">
        <ListGroup>
          <Link to="/start" className="list-group-item list-group-item-action">ログイン</Link>
        </ListGroup>
      </div>
    </div>
  );
};


export default AdmIndex;
