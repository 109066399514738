import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import { history } from 'store/configureStore';
import { withAuthenticator, SignIn, RequireNewPassword, ForgotPassword } from 'aws-amplify-react';


// スタイル
import * as style from 'styles/util';
import { Container, Row } from 'react-bootstrap';

// パーツコンポーネント
import Header from 'components/parts/header';
import Breadcrumb from 'components/parts/breadcrumb';
import Sidebar from 'components/parts/sidebar';

// ページ
import AdmForms from 'components/adm/forms';
import AdmAccounts from 'components/adm/accounts';
import AdmAccountsDetail from 'components/adm/accounts/detail';
import AdmAccountsCount from 'components/adm/accounts/count';
import SrvUsers from 'components/srv/users';
import SrvUsersDetail from 'components/srv/users/detail';
import SrvUsersCount from 'components/srv/users/count';
import Start from 'components/start';

const WithAuth = withAuthenticator(
  () => (
    <style.Wrapper>
      <Header />
      <Breadcrumb />
      <Container fluid="on">
        <Row>
          <Sidebar />
          <ConnectedRouter history={history}>
            <Switch>
              {/* ログイン直後の画面（リダイレクト用） */}
              <Route exact path='/start' component={Start} />

              {/* 管理画面（アカウント） */}
              {/* 利用申請一覧画面 */}
              <Route exact path='/adm/forms' component={AdmForms} />
              {/* 利用申請モーダル */}
              <Route exact path='/adm/forms/:id' component={AdmForms} />
              {/* アカウント一覧画面 */}
              <Route exact path='/adm/accounts' component={AdmAccounts} />
              {/* 推定アカウント数画面 */}
              <Route exact path='/adm/accounts/count' component={AdmAccountsCount} />
              {/* アカウント詳細画面 */}
              <Route exact path='/adm/accounts/:id' component={AdmAccountsDetail} />

              {/* 管理画面（ユーザー） */}
              {/* ユーザー一覧画面 */}
              <Route exact path='/srv/users' component={SrvUsers} />
              {/* 推定ユーザー数画面 */}
              <Route exact path='/srv/users/count' component={SrvUsersCount} />
              {/* ユーザー詳細画面 */}
              <Route exact path='/srv/users/:id' component={SrvUsersDetail} />

            </Switch>
          </ConnectedRouter>
        </Row>
      </Container>
    </style.Wrapper>
  ),
  false,
  [
    <SignIn />,
    <RequireNewPassword />,
    <ForgotPassword />
  ]
);

const WithAuthRoutes = props => {
  return <WithAuth {...props} />;
};

export default WithAuthRoutes;
