import { actions } from 'actions';

/**
 * メッセージセットアクション
 * 
 * @param string type info|warning|dangerなどのbootstrapメッセージタイプ
 * @param string text 表示メッセージ
 */
export const setMessage = (type, text) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_MESSAGE,
      message_type: type,
      message_text: text,
    });
  };
};


/**
 * メッセージクリアーアクション
 * 
 */
export const clearMessage = () => {
  return (dispatch) => {
    dispatch({
      type: actions.CLEAR_MESSAGE,
      message_type: '',
      message_text: '',
    });
  };
};