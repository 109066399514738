import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import FA from 'react-fontawesome';

/**
 * ユニットローディングコンポーネント
 */
class unitLoading extends Component {

  // ローディングがセットされていれば表示する
  render() {
    // ローディングがセットされていない場合
    if (!this.props.loadingState.unitLoading) {
      return '';
    }
    return (
      <LoadWrap>
        <FA name="refresh" className="loading"/>
      </LoadWrap>
    );
  }
}


const LoadWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    height: 100%;
    padding-top: 10px;
    padding-left: 10px;
    min-height: calc(100vh - 90px);
    background-color: #F5F7F7;

    .fa.fa-refresh {
      font-size: 2rem;

    }
`;

const mapStateToProps = state => {
  return { loadingState: state.loading };
};

export default connect(mapStateToProps)(unitLoading);

