import { actions } from 'actions';
import axios from 'axios';
import { consoleLogger } from 'helpers';
import client_config from 'client-config';

/**
 * ヘッダーリセット 
 */
export const clearHeader = () => {
  return (dispatch) => {
    dispatch({
      type: actions.CLEAR_HEADER,
    });
  };
};

/**
 * ヘッダー取得＋セットアクション
 * 
 * @param type button|unit|page
 */
export const getHeader = (jwtToken = '', email = '') => {
  return (dispatch) => {
    const url = '/auth/cmn/headers';

    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': jwtToken }
    };
    return axios(requestData)
      .then(res => {
        if (res.status === 200) {
          let groups = [];
          let isAccount = false;
          let isUser = false;

          consoleLogger('API GET:' + url);
          consoleLogger(res);

          // APIの返却値にグループがあればtrueにする
          if (res.data.item && res.data.item.groups) {
            groups = res.data.item.groups.split(',');
            if (groups.includes('AdminGroup')) {
              isAccount = true;
            }
            if (groups.includes('ServiceGroup')) {
              isUser = true;
            }
          }
          dispatch({
            type: actions.SET_HEADER,
            isAccount: isAccount,
            isUser: isUser,
            mail: email
          });
        } else {
          consoleLogger('statuscode:' + url, res.data);
          throw new Error(JSON.stringify(res.data));
        }
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        consoleLogger('catch:' + url, err);
        throw new Error(err);
      });
  };
};
