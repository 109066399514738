import React, { Component } from 'react';

class Logout extends Component {
  constructor(...props) {
    super(...props);
    window.location.href = '/';
  }

  render() {
    return (
      <div>
      </div>
    );
  }
}

export default Logout;
