const messages = {
  required: '必須項目です。',
  mail: '正しい形式でご入力ください。',
  tel: '+半角数字で入力してください。',
  any: 'どちらかにチェックを付けてください。',
  maxLength: '%s文字以内で入力してください。',
  notMailStr: '半角英数記で入力してください。', // 仕様書に「号」の文字記載無し？ 誤植か後で確認
  notMail: 'メールアドレスが正しくありません。',
  notMailDomain: '入力のドメインでは申請できません。',
};

export const required = (value) => (value || typeof value === 'number' ? undefined : messages.required);

export const tel = (value) => (value && !value.match(/^\+[0-9]+$/) ? messages.tel : undefined);

// 引数を渡すとエラーに無限ループでエラーになるので使わない？
export const maxLength = (value, length) => {
  if (value && value.length > length) {
    return messages.maxLength.replace('%s', length);
  }
};

export const maxLength20 = (value) => (value && value.length > 20) ? messages.maxLength.replace(/%s/, '20') : undefined;
export const maxLength40 = (value) => (value && value.length > 40) ? messages.maxLength.replace(/%s/, '40') : undefined;
export const maxLength100 = (value) => (value && value.length > 100) ? messages.maxLength.replace(/%s/, '100') : undefined;
export const maxLength200 = (value) => (value && value.length > 200) ? messages.maxLength.replace(/%s/, '200') : undefined;

export const mail = (value) => {
  // 半角英数記号以外
  if (!value.match(/^[a-zA-Z0-9@\-_.*!#$%&’*+/=?^`{|}]*$/)) {
    return messages.notMailStr;
  }

  // ＠が含まれない
  if (!value.match(/@{1}/)) {
    return messages.notMail;
  }

  // ローカル部が1-64文字以外
  if (!value.match(/^[^@]{1,64}@/)) {
    return messages.notMail;
  }

  // メールアドレスはローカルはSCドメイン等追加
  const match = '@(' + process.env.REACT_APP_MAIL_DOMAIN + ')$';
  const re = new RegExp(match);

  // ドメイン部が定義済ドメイン以外
  if (!value.match(re)) {
  //if (!value.match(/@alpsalpine\.com$/)) {
    return messages.notMailDomain;
  }
};