import React, { Component } from 'react';
import styled from 'styled-components';
import * as style from 'styles/util';
import { ButtonToolbar } from 'react-bootstrap';
import { Table } from 'react-bootstrap';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { connect } from 'react-redux';
import axios from 'axios';
import Message from 'components/parts/message';
import ButtonLoading from 'components/parts/loading/buttonLoading';
import UnitLoading from 'components/parts/loading/unitLoading';
import { consoleLogger } from 'helpers';
import { withAuthenticator } from 'aws-amplify-react';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';

class SrvUsersCount extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...props) {
    super(...props);

    // local state.
    this.state = {
      common_num: '',
      brand_num: '',
      sp_num: '',
      spLauncher_num: '',
      spFlect_num: '',
      connectedViewer_num: '',
      locationViewer_num: '',
      isSp: false
    };

    this.cancelToken = axios.CancelToken.source();

    // メッセージ初期化
    this.props.clearMessage();

    // ローディングスタート
    this.props.setLoading('unit');

    this.refresh();
  }

  /**
   * 推定ユーザー数のリフレッシュ
   */
  refresh = async () => {
    // ローディングスタート（ボタン）
    this.props.setLoading('button', 'refreshUser');
    const url = '/auth/srv/users/count';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200 && res.data.items && res.data.items.length) {
          // 推定ユーザー数セット
          let commonNum = 0;
          let brandNum = 0;
          let spNum = 0;
          let spLauncherNum = 0;
          let spFlectNum = 0;
          let connectedViewerNum = 0;
          let locationViewerNum = 0;
          let isSp = false;

          res.data.items.forEach(item => {
            // 共通プールはクライアントがない
            if (!item.client) {
              commonNum = item.UserPool.EstimatedNumberOfUsers;
            } else if (item.client.service.service_name === 'ブランドビジネス') {
              brandNum = item.UserPool.EstimatedNumberOfUsers;
            } else if (item.client.service.service_name === 'スマホナビ') {
              spNum = item.UserPool.EstimatedNumberOfUsers;
              isSp = true;
            } else if (item.client.service.service_name === 'スマホナビランチャー') {
              spLauncherNum = item.UserPool.EstimatedNumberOfUsers;
            } else if (item.client.service.service_name === 'スマホナビフレクト') {
              spFlectNum = item.UserPool.EstimatedNumberOfUsers;
            } else if (item.client.service.service_name === 'ALPINE CONNECTED VIEWER') {
              connectedViewerNum = item.UserPool.EstimatedNumberOfUsers;
            } else if (item.client.service.service_name === 'ALAP LOCATION MANAGEMENT VIEWER') {
              locationViewerNum = item.UserPool.EstimatedNumberOfUsers;
            }
          });

          this.setState({
            common_num: commonNum,
            brand_num: brandNum,
            sp_num: spNum,
            spLauncher_num: spLauncherNum,
            spFlect_num: spFlectNum,
            connectedViewer_num: connectedViewerNum,
            locationViewer_num: locationViewerNum,
            isSp: isSp
          });
          this.props.clearMessage();
        } else {
          this.props.setMessage('danger', `推定ユーザー数の取得に失敗しました。\n${res.data}`);
          consoleLogger('statuscode:' + url, res.data);
        }
        this.props.clearLoading('button', 'refreshUser');
        this.props.clearLoading('unit');
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        this.props.clearLoading('button', 'refreshUser');
        this.props.clearLoading('unit');

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        const res = err.response;

        // その他のエラー
        consoleLogger('catch error:' + url, err);
        if (res) {
          consoleLogger('catch error:' + url, res.data.errcode);
        }
        this.props.setMessage('danger', `推定ユーザー数の取得に失敗しました。\n${err}`);

      });
  };

  render() {
    const formatter = new Intl.NumberFormat('ja-JP');
    return (
      <CountWrap className="col-6 pt-3">
        <UnitLoading />
        <ButtonToolbar className="flex-right justify-content-end">
          <style.ReloadButton variant="link" onClick={this.refresh}>
            <ButtonLoading buttonName="refreshUser" />
          </style.ReloadButton>
        </ButtonToolbar>
        <Message />
        <Table bordered striped hover className="user-num-table">
          <tbody>
            <tr className="header-tbody">
              <th className="bg-white" colSpan="2">推定ユーザー数</th>
              <th>合計</th>
              <td>{ formatter.format(this.state.brand_num + this.state.sp_num + this.state.spLauncher_num + this.state.spFlect_num + this.state.connectedViewer_num + this.state.locationViewer_num + this.state.common_num) }</td>
            </tr>
            <tr>
              <th className="bg-white border-bottom-0">内訳</th>
              <th className="bg-white" >ID/PW</th>
              <th>共通</th>
              <td>{ formatter.format(this.state.common_num) }</td>
            </tr>
            <tr>
              <th className="bg-white border-top-0" rowSpan="7"></th>
              <th className="bg-white" rowSpan="7">IDPフェデレーション</th>
              <th>ブランドビジネス</th>
              <td>{ formatter.format(this.state.brand_num) }</td>
            </tr>
            {this.state.isSp && <tr>
              <th>スマホナビ</th>
              <td>{formatter.format(this.state.sp_num) }</td>
            </tr>}
            <tr>
              <th>スマホナビランチャー</th>
              <td>{formatter.format(this.state.spLauncher_num) }</td>
            </tr>
            <tr>
              <th>スマホナビフレクト</th>
              <td>{formatter.format(this.state.spFlect_num) }</td>
            </tr>
            <tr>
              <th>ALPINE CONNECTED VIEWER</th>
              <td>{formatter.format(this.state.connectedViewer_num) }</td>
            </tr>
            <tr>
              <th>ALAP LOCATION MANAGEMENT VIEWER</th>
              <td>{formatter.format(this.state.locationViewer_num) }</td>
            </tr>
            <tr>
              <th>小計</th>
              <td>{ formatter.format(this.state.brand_num + this.state.sp_num + this.state.spLauncher_num + this.state.spFlect_num + this.state.connectedViewer_num + this.state.locationViewer_num) }</td>
            </tr>
          </tbody>
        </Table>
      </CountWrap>
    );
  }
}

const CountWrap = styled.div`

  .bg-white {
    background-color: #FFF !important;
  }

  td {
    text-align: right;
  }
`;

const mapStateToProps = () => {
  return {};
};

export default withAuthenticator(connect(mapStateToProps, { setMessage, clearMessage, setLoading, clearLoading })(SrvUsersCount));
