import React from 'react';
import styled from 'styled-components';
import { Container } from 'react-bootstrap';

const NotFound = () => {
  return (
    <Container fluid="on">
      <Header>Sorry, page not found!</Header>
    </Container>
  );
};

const Header = styled.h3`
  width: 100%;
  text-align: center;
`;

export default NotFound;
