import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import styled from 'styled-components';
import InputText from 'components/parts/form/inputText';
import Select from 'components/parts/form/select';
import { Alert } from 'react-bootstrap';
import Check from 'components/parts/form/check';
import { userSearchType } from 'const';
import * as style from 'styles/util';
import { connect } from 'react-redux';

const SearchForm = props => {
  const { handleSubmit, error, loadingState, serviceList } = props;
  const options = userSearchType;
  return (
    <div>
      <style.SearchbarForm onSubmit={handleSubmit} className="rounded">
        <Form.Group className="mb-0">
          <Field
            name="search_attr"
            component={Select}
            inline="on"
            validate={[]}
            className="align-top no-border-radius rounded-left col-3 search-type border-right-0"
            novalid="on"
            options={options}
          />
          <Field
            name="search_str"
            required="off"
            component={InputText}
            placeholder="検索ワードを入力"
            inline="on"
            validate={[]}
            className="align-top no-border-radius col-8 search-box"
            novalid="on"
          />
          <Button variant="primary" disabled={loadingState} type="submit" className="align-top no-border-radius rounded-right col-1">検索</Button>
        </Form.Group>
        <Form.Group className="pl-0 pt-0 pb-0 mb-0 border border-top-0 rounded-bottom">
          <div className="col-3 search-type float-left text-left border-right" style={{height: "50px"}}>
            サービス指定
          </div>
          {serviceList.map((data) => {
            const service_name = (data.service.service_p_name === 'virtual_all' ? '全サービス' :data.service.service_name);
            const service_p_name = data.service.service_p_name;
            return (
              <Field
                name="search_service"
                label={service_name}
                component={Check}
                className="pl-1"
                type="radio"
                value={service_p_name}
                id={'search_service-' + service_p_name}
                key={'search_service-' + service_p_name}
                validate={[]}
                feedback="off"
                inline="on"
              />
            );
          })}
        </Form.Group>
        {error && <Alert variant="danger" >{error}</Alert>}
      </style.SearchbarForm>
      <Description>※ユーザーが加入中の全てのサービスを確認したい場合はsub（ユーザー識別子）をクリックして詳細画面に遷移してください。</Description>
    </div>
  );
};

const Description = styled.p`
  color: #FF0000;
`;

const mapStateToProps = (state, props) => ({
  initialValues: { search_attr: props.initial.search_attr, search_str: props.initial.search_str, search_service: props.initial.search_service },
});

export default connect(
  mapStateToProps
)(reduxForm({
  form: '_UserListSearchForm',
})(SearchForm));
