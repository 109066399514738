import React, { Component } from 'react';
import { Breadcrumb } from 'react-bootstrap';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { history } from 'store/configureStore';
import { breadcrumbList } from 'const';
import { connect } from 'react-redux';


class AuthedBreadcrumb extends Component {

  breadcrumb = () => {
    // パンくずの判定のために、パラメータ部分を「/」以外の文字での判定にする
    let found = Object.keys(breadcrumbList).find((pageVal) => {
      // パスのパラメータ部分を正規表現用に変換（ただしcountは除外）
      const page = pageVal.replace(/:[0-9a-zA-Z_-]*/g, '[^/]*');
      const reg = new RegExp(page);
      if (typeof history !== 'undefined' && typeof history.location !== 'undefined' && typeof history.location.pathname !== 'undefined') {
        if (history.location.pathname.match(reg)) {
          return true;
        }
      }
      return false;
    });
    // パンくずリストが見つからない場合
    if (typeof found === 'undefined') {
      found = [];
    }
    // 一致する
    if (breadcrumbList[found]) {
      const breadcrumb = breadcrumbList[found];
      return (
        <Breadcrumb>
          {Object.keys(breadcrumb).map((title, index) => {
            if (breadcrumb[title] === '') {
              // リンク無し
              return (
                <Breadcrumb.Item active key={index}>{title.replace(/%s/, this.props.breadcrumbState.breadcrumb)}</Breadcrumb.Item>
              );
            }

            // リンクあり
            return (
              <li className="breadcrumb-item" key={index}>
                <Link to={breadcrumb[title]}>{title.replace(/%s/, this.props.breadcrumbState.breadcrumb)}</Link>
              </li>
            );
          })}
        </Breadcrumb>
      );
    }

    return '';
  }
  render() {
    return (
      <BreadCrumbWrap>
        {this.breadcrumb()}
      </BreadCrumbWrap>
    );
  }
}

const BreadCrumbWrap = styled.div`
  /* メニューとの余白を開けないようにしている */
  ol {
    margin-bottom: 0;
  }
`;

const mapStateToProps = state => {
  return { breadcrumbState: state.breadcrumb };
};

export default connect(mapStateToProps)(AuthedBreadcrumb);
