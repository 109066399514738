const _client_config = [];
_client_config['local'] = {
  'client_id': '5tomh3o0bur212k32sknvo1jlb',
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_WuyUo7Nzg',
  'api_url': 'http://localhost:3000',
};
_client_config['scdev'] = {
  'client_id': '5tomh3o0bur212k32sknvo1jlb',
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_WuyUo7Nzg',
  'api_url': 'https://hgxia2nhh0.execute-api.ap-northeast-1.amazonaws.com/dev',
};
_client_config['mydev'] = {
  'client_id': '581ur8tvmivn2ruk8e335p3v3d',
  'region': 'us-west-2',
  'UserPoolId': 'us-west-2_Ti1ZTqlnt',
  'api_url': 'https://iryoiytlfb.execute-api.us-west-2.amazonaws.com/mydev',
};
_client_config['dev'] = {
  'client_id': '27p0qnk3s94kb5qq7ttsdeot0v',
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_QyKLf5Yda',
  'api_url': 'https://n2701vbncj.execute-api.ap-northeast-1.amazonaws.com/dev',
};
_client_config['stg'] = {
  'client_id': '1r9e6325sikvr6fou6vumg4t6j',
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_MXBoOTsxT',
  'api_url': 'https://bzqnr57i7k.execute-api.ap-northeast-1.amazonaws.com/stg',
};
_client_config['prd'] = {
  'client_id': '5pssmuhfq08lfj6b47gh71oui2',
  'region': 'ap-northeast-1',
  'UserPoolId': 'ap-northeast-1_mqztGHLQm',
  'api_url': 'https://vjwckoe0oj.execute-api.ap-northeast-1.amazonaws.com/prd',
};
const client_config = _client_config[process.env.REACT_APP_PUBLISH_ENV];

export default client_config;
