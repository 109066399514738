import { actions } from 'actions';

const initalState = [
  {
    breadcrumb: ''
  }
];

/**
 * パンくずリストの任意文字列部分
 */
const breadcrumbReducer = (state = initalState, action) => {
  switch (action.type) {
    // メッセージセット
    case actions.SET_BREADCRUMB:
      return {
        ...state,
        breadcrumb: action.breadcrumb
      };
    // メッセージクリアー
    case actions.CLEAR_BREADCRUMB:
      return {
        ...state,
        breadcrumb: ''
      };
    default:
      return state;
  }
};

export default breadcrumbReducer;
