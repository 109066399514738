import React, { Component } from 'react';
import styled from 'styled-components';
import * as style from 'styles/util';
import { Table, ButtonToolbar, Button } from 'react-bootstrap';
import { validType } from 'const';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { setPageData } from 'actions/payloadAction';
import { setBreadcrumb, clearBreadcrumb } from 'actions/breadcrumbAction';
import { connect } from 'react-redux';
import StatusInfoPopup from '../statusInfoPopup';
import Message from 'components/parts/message';
import ButtonLoading from 'components/parts/loading/buttonLoading';
import UnitLoading from 'components/parts/loading/unitLoading';
import axios from 'axios';
import { formatDate, consoleLogger } from 'helpers';
import { withAuthenticator } from 'aws-amplify-react';
import { nl2br } from 'helpers';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';

class AdmAccountsDetail extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...props) {
    super(...props);

    // パンくずリストの変数初期化
    this.props.clearBreadcrumb();

    const {params} = this.props.match;

    // local state.
    this.state = {
      id: params.id,
      memberData: null,
      loading: false,
    };

    this.cancelToken = axios.CancelToken.source();

    // メッセージ初期化
    this.props.clearMessage();

    // ローディングスタート
    this.props.setLoading('unit');

    this.refresh(true);
  }

  /**
   * リフレッシュ
   * 
   * @param boolean initial:初回のみ（mount時にsetStateすると怒られるので）
   * @param boolean rotate:くるくるを回すかどうか
   */
  refresh = async (initial = false, rotate = true) => {
    // ローディングスタート（ボタン）
    if (rotate) {
      this.props.setLoading('button', 'refreshAccountDetail');
    }

    // 初期表示では実行しない
    if (!initial) {
      // リフレッシュ中は他ボタンもすべて無効化しておく
      this.setState({loading: true});
    }

    const url = '/auth/adm/accounts/' + this.state.id;

    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get',
      url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        if (res.status === 200 && res.data.item && res.data.item.account) {
          // 詳細をセット
          this.setState({
            memberData: res.data.item
          });
          // パンくずリストの名前格納
          this.props.setBreadcrumb(res.data.item.account.Attributes.name);
        } else {
          this.props.setMessage('danger', `管理者アカウントの取得に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('statuscode:' + url, res.data);
        }
        this.props.clearLoading('button', 'refreshAccountDetail');
        this.props.clearLoading('unit');
        this.setState({ loading: false });
      })
      .catch(err => {
        const res = err.response;

        this.props.clearLoading('button', 'refreshAccountDetail');
        this.props.clearLoading('unit');
        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.props.setMessage('warning', '管理者アカウントデータは存在しません。');
        } else {
          // エラー時、 ローディングクリア＋ログ出力
          this.props.setMessage('danger', `管理者アカウントの取得に失敗しました。再実行してください。\n${err}`);
          consoleLogger('catch:' + url, err);
        }
        this.setState({ loading: false });
      });
  };

  /**
   * 有効化/無効化処理
   */
  changeValid = async (valid) => {
    const url = '/auth/adm/accounts/' + this.state.id;

    const data = {
      enabled: valid ? '1' : '0'
    };
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'put',
      url: client_config['api_url'] + url,
      params: data,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    this.setState({ loading: true });

    return axios(requestData)
      .then(res => {
        consoleLogger('API PUT:' + url);
        consoleLogger(res);
        if (res.status === 200 && !res.data.errcode) {
          // 成功時
          // 再度データリフレッシュする
          let message = '';
          if (valid) {
            message = '管理者アカウントを有効にしました。';
          } else {
            message = '管理者アカウントを無効にしました。';
          }

          // 一覧データを保持している場合は一覧データも書き換える
          if (this.props.pageData.admAccounts && this.props.pageData.admAccounts.list.length) {
            const admAccounts = this.props.pageData.admAccounts;
            const email = this.state.memberData.account.Attributes.email;
            admAccounts.list.forEach((data, index) => {
              // 対象ユーザー
              if (data.account && data.account.Attributes && data.account.Attributes.email && data.account.Attributes.email === email) {
                if (valid) {
                  data.account.Enabled = true;
                } else {
                  data.account.Enabled = false;
                }

                admAccounts.list[index] = data;
              }
            });
            this.props.setPageData('admAccounts', admAccounts);
          }

          return this.refresh(false, false)
            .then(() => {
              this.props.setMessage('success', message);
            })
            .catch((err) => {
              // このタイミングで一リフレッシュエラーが出ることは想定していないがエラーはログに出す
              // その他のエラー
              if (err.constructor && err.constructor.name === 'Cancel') {
                return false;
              }

              consoleLogger('catch error: after put item.' + url, err);
              this.props.setMessage('success', message);
            });
        } else {
          // その他のエラー
          if (valid) {
            this.props.setMessage('danger', `管理者アカウントの有効化に失敗しました。再実行してください。\n${res.data}`);
          } else {
            this.props.setMessage('danger', `管理者アカウントの無効化に失敗しました。再実行してください。\n${res.data}`);
          }
          consoleLogger('other error:' + url, res.data.errcode);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const res = err.response;

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.setState({ loading: false });

        if (res && res.status === 400 && res.data.errcode === 'ITEM_NOT_FOUND') {
          this.props.setMessage('warning', '対象の管理者アカウントが見つかりません。');
        } else {
          // その他のエラー
          consoleLogger('catch error:' + url, err);
          if (res) {
            consoleLogger('catch error:' + url, res.data.errcode);
          }

          if (valid) {
            this.props.setMessage('danger', `管理者アカウントの有効化に失敗しました。再実行してください。\n${err}`);
          } else {
            this.props.setMessage('danger', `管理者アカウントの無効化に失敗しました。再実行してください。\n${err}`);
          }
        }
      });
  }

  /**
   * 削除処理
   */
  execDelete = async () => {
    const url = '/auth/adm/accounts/' + this.state.id;

    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'delete',
      url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    this.setState({ loading: true });

    return axios(requestData)
      .then(res => {
        consoleLogger('API DELETE:' + url);
        consoleLogger(res);
        if (res.status === 200 && !res.data.errcode) {
          // 削除後は一覧へ遷移
          this.props.setMessage('success', '管理者アカウントを削除しました。');
          this.props.history.push({
            pathname: '/adm/accounts',
            state: { 
              keepMessage: true,        // 一覧画面表示時にメッセージを残す
              deleteAccount: this.state.id  // 一覧画面表示時に対象の管理者アカウントを抜く
            }
          });
        } else {
          // その他のエラー
          this.props.setMessage('danger', `管理者アカウントの削除に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('other error:' + url, res.data.errcode);
          this.setState({ loading: false });
        }
      })
      .catch((err) => {
        const res = err.response;

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.setState({ loading: false });

        if (res && res.status === 400 && res.data.errcode === 'SNS_NOT_CONFIRMED') {
          this.props.setMessage('warning', 'SNSのメール購読確認が保留中のため削除できません。利用者が購読確認するまでお待ちください。');
        } else {
          // その他のエラー
          consoleLogger('catch error:' + url, err);
          if (res) {
            consoleLogger('catch error:' + url, res.data.errcode);
          }
          this.props.setMessage('danger', `管理者アカウントの削除に失敗しました。再実行してください。\n${err}`);
        }
      });
  }

  render() {
    const memberData = this.state.memberData;
    return (
      <div className="col-10 pt-3 pb-3">
        <UnitLoading />
        <div className="overflow-hidden">
          {memberData &&
            <ButtonToolbar className="float-right" aria-label="Toolbar with Button groups">
              {memberData.account.Enabled ? (
                <TopButton disabled={this.state.loading} variant="primary" onClick={() => this.changeValid(false)} className="def-btn-long">
                  無効化
                </TopButton>
              ) : (
                <div>
                  <TopButton disabled={this.state.loading} variant="danger" onClick={this.execDelete} className="def-btn-long">
                    削除
                  </TopButton>
                  <TopButton disabled={this.state.loading} variant="primary" onClick={() => this.changeValid(true)} className="def-btn-long">
                    有効化
                  </TopButton>
                </div>
              )}
            </ButtonToolbar>}
        </div>
        <Message />
        {memberData &&
          <Table striped bordered hover>
            <thead>
              <tr>
                <th colSpan="2">
                  管理者アカウント詳細
                  <style.ReloadButton disabled={this.state.loading} variant="link" onClick={() => this.refresh()} className="float-right">
                    <ButtonLoading buttonName="refreshAccountDetail" />
                  </style.ReloadButton>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <TdWidth>グループ</TdWidth>
                <td>{memberData.groups.replace(',', '　')}</td>
              </tr>
              <tr>
                <td>名前</td>
                <td>{memberData.account.Attributes.name}</td>
              </tr>
              <tr>
                <td>所属</td>
                <td>{memberData.account.Attributes.preferred_username}</td>
              </tr>
              <tr>
                <td>Eメール</td>
                <td>{memberData.account.Attributes.email}</td>
              </tr>
              <tr>
                <td>外線直通電話番号</td>
                <td>{memberData.account.Attributes.phone_number}</td>
              </tr>
              <tr>
                <td>管理者権限</td>
                <td>{(memberData.account.Attributes.given_name && memberData.account.Attributes.given_name.match(/^1/)) ? '○' : ''}</td>
              </tr>
              <tr>
                <td>申請理由</td>
                <td>{nl2br(memberData.account.CustomAttributes['dev:custom:remarks'])}</td>
              </tr>
              <tr>
                <td>sub（ユーザー識別子）</td>
                <td>{memberData.account.Username}</td>
              </tr>
              <tr>
                <td>有効状態</td>
                <td>{validType[memberData.account.Enabled]}</td>
              </tr>
              <tr>
                <td>
                  ステータス
                  <StatusInfoPopup />
                </td>
                <td>{memberData.account.UserStatus}</td>
              </tr>
              <tr>
                <td>作成日時</td>
                <td>{formatDate(memberData.account.UserCreateDate)}</td>
              </tr>
              <tr>
                <td>最終更新日時</td>
                <td>{formatDate(memberData.account.UserLastModifiedDate)}</td>
              </tr>
            </tbody>
          </Table>}
      </div>
    );
  }
}

const TdWidth = styled.td`
    width: 220px;
`;

const TopButton = styled(Button)`
  margin-left: 10px;
  margin-bottom: 10px;
`;

const mapStateToProps = (state) => {
  return { pageData: state.payload };
};

export default withAuthenticator(connect(mapStateToProps, { setPageData, setMessage, clearMessage, setLoading, clearLoading, setBreadcrumb, clearBreadcrumb})(AdmAccountsDetail));
