import { actions } from 'actions';

const initalState = [
  {
    buttonLoading: {}, // リロードボタン部分のローディング（リロードボタンは画面内に複数あるので
    unitLoading: false,   // 画面のコンテンツ部分のローディング
    pageLoading: false,   // 画面全体部分のローディング
    overlay: false, // オーバーレイの表示
  }
];

/**
 * ローディング
 */
const loadingReducer = (state = initalState, action) => {
  switch (action.type) {
    // ボタン部分のローディング
    case actions.SET_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: Object.assign(state[0].buttonLoading, { [action.buttonName]: true})
      };
    // ボタン部分のローディングクリアー
    case actions.CLEAR_BUTTON_LOADING:
      return {
        ...state,
        buttonLoading: Object.assign(state[0].buttonLoading, { [action.buttonName]: false })
      };
    // ユニット部分のローディング
    case actions.SET_UNIT_LOADING:
      return {
        ...state,
        unitLoading: true
      };
    // ユニット部分のローディングクリアー
    case actions.CLEAR_UNIT_LOADING:
      return {
        ...state,
        unitLoading: false
      };
    // 画面部分のローディング
    case actions.SET_PAGE_LOADING:
      return {
        ...state,
        pageLoading: true
      };
    // 画面部分のローディングクリアー
    case actions.CLEAR_PAGE_LOADING:
      return {
        ...state,
        pageLoading: false
      };
    // オーバーレイのセット
    case actions.SET_OVERLAY:
      return {
        ...state,
        overlay: true
      };
    // オーバーレイのクリアー
    case actions.CLEAR_OVERLAY:
      return {
        ...state,
        overlay: false
      };
    default:
      return state;
  }
};

export default loadingReducer;
