import React, { Component } from 'react';
import styled from 'styled-components';
import { Field, reduxForm } from 'redux-form';
import { Table, Form, Button } from 'react-bootstrap';
import * as Validation from 'validators/validation';
import InputText from 'components/parts/form/inputText';
import TextArea from 'components/parts/form/textArea';
import { Alert } from 'react-bootstrap';
import Message from 'components/parts/message';
import Check from 'components/parts/form/check';
import * as style from 'styles/util';
import { setMessage, clearMessage } from 'actions/messageAction';
import { connect } from 'react-redux';
import { nl2br } from 'helpers';

class RegisterForm extends Component {

  constructor(...args) {
    super(...args);
    this.state = {
      confirm: false,
      values: null,
    };
  }

  // 確認ボタン
  confirm = values => {
    if (!values.srv_chk && !values.adm_chk) {
      // エラー時（仮）
      this.props.setMessage('danger', '申請する利用権限を設定してください。');
      return false;
    }

    this.props.clearMessage();
    this.setState({
      confirm: true,
      values: values
    });

  }

  // 修正ボタン
  back = () => {
    this.props.clearMessage();
    this.setState({
      confirm: false,
      values: null
    });
  }

  render() {
    const { handleSubmit, submitting, error, submited } = this.props;
    const { values, confirm } = this.state;
    return (
      <div>
        {!confirm && <div>
          <style.SimpleHead>認証基盤管理システム 利用申請</style.SimpleHead>
          <FormWrapper className="col-8 col-lg-6 mx-auto">
            <p id="description" className="mb-2">
              認証基盤管理システムの利用を申請します。<br />
              以下の項目を入力して申請をクリックしてください。<br />
              申請の承認には数日かかります。承認された場合のみメールでログイン情報をお送りします。<br />
              【ご注意】<br />
              ・申請が却下された場合は連絡メールは届きません。5営業日が過ぎても連絡がない場合は却下されています。<br />
              ・メールはjunkフォルダーに振り分けられる場合もございますので、同フォルダーへの連絡にもご注意ください。
            </p>
            <Form onSubmit={handleSubmit(this.confirm)}>
              <Field
                name="name"
                label="名前"
                required="on"
                component={InputText}
                className="col-4"
                placeholder="名前を入力"
                validate={[
                  Validation.required,
                  Validation.maxLength40,
                ]}
              />
              <Field
                name="preferred_username"
                label="所属"
                required="on"
                className="col-8"
                component={InputText}
                placeholder="所属を入力"
                validate={[
                  Validation.required,
                  Validation.maxLength100,
                ]}
              />
              <Field
                name="email"
                label="Eメール"
                required="on"
                className="col-8"
                component={InputText}
                placeholder="Eメールを入力"
                validate={[
                  Validation.required,
                  Validation.mail
                ]}
              />
              <Field
                name="phone_number"
                label="外線直通電話番号（ハイフンなし　国際電話番号形式）"
                required="on"
                className="input-tel"
                component={InputText}
                placeholder="電話番号を入力"
                validate={[
                  Validation.required,
                  Validation.tel,
                  Validation.maxLength20,
                ]}
              />
              <div className="form-group">
                <span className="pr-4">申請する利用権限</span>
                <Field
                  name="srv_chk"
                  label="ユーザー管理権限"
                  component={Check}
                  type="checkbox"
                  value="1"
                  id="dec-form-check-1"
                  validate={[]}
                  feedback="off"
                  inline="on"
                />
                <Field
                  name="adm_chk"
                  label="管理者アカウント管理権限"
                  component={Check}
                  type="checkbox"
                  value="1"
                  id="dec-form-check-2"
                  validate={[]}
                  feedback="off"
                  inline="on"
                />
              </div>
              <Field
                name="remarks"
                label="申請理由"
                required="off"
                className="col-8 row-3"
                component={TextArea}
                placeholder="申請理由を入力"
                validate={[
                  Validation.maxLength200,
                ]}
              />
              {error && <Alert variant="danger" >{error}</Alert>}
              <Message />
              <div className="text-center mt-4">
                {!submited && <Button variant="primary" disabled={submitting} type="submit" className="def-btn-long submit">確認</Button>}
              </div>
            </Form>
          </FormWrapper>
        </div>}
        {confirm && <div>
          <style.SimpleHead>認証基盤管理システム 利用申請確認</style.SimpleHead>
          <FormWrapper className="col-8 col-lg-6 mx-auto">
            <p className="mb-2 confirm-description">
              以下の内容で利用申請を行います。<br />
              申請内容に間違いがないか、再度ご確認ください。
            </p>
            <Table striped bordered hover className="col-12 mt-4 confirm-table">
              <thead>
                <tr>
                  <th colSpan="2">
                    申請内容
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <TdWidth>名前</TdWidth>
                  <td>{values.name}</td>
                </tr>
                <tr>
                  <td>所属</td>
                  <td>{values.preferred_username}</td>
                </tr>
                <tr>
                  <td>Eメール</td>
                  <td>{values.email}</td>
                </tr>
                <tr>
                  <td>外線直通電話番号</td>
                  <td>{values.phone_number}</td>
                </tr>
                <tr>
                  <td>申請利用権限</td>
                  <td>{values.srv_chk && 'ユーザー管理権限'}{values.srv_chk && values.adm_chk && '　'}{values.adm_chk && '管理者アカウント管理権限'}</td>
                </tr>
                <tr>
                  <td>申請理由</td>
                  <td>{nl2br(values.remarks)}</td>
                </tr>
              </tbody>
            </Table>
            {error && <Alert variant="danger" >{error}</Alert>}
            <Message />
            {!submited && <div className="text-center">
              <Button variant="secondary" disabled={submitting} onClick={this.back} type="submit" className="repair def-btn-long mr-4">修正</Button>
              <Button variant="primary" disabled={submitting} onClick={handleSubmit} type="submit" className="submit def-btn-long">申請</Button>
            </div>}
          </FormWrapper>
        </div>}
      </div>
    );
  }
}

const FormWrapper = styled.div`
  .form-label {
    margin-bottom: .2rem;
  }
  .form-group {
    margin-bottom: .5rem;
  }
`;

const TdWidth = styled.td`
    width: 220px;
`;

// サブミット後に何かをする場合の処理
// const afterSubmit = (result, dispatch) =>{
//   // サブミット後にボタンを非表示する
//   // dispatch(reset('_RegisterForm'));
// };

const mapStateToProps = () => {
  return {};
};

const form = reduxForm({
  form: '_RegisterForm',
  // onSubmitSuccess: afterSubmit,
  initialValues: { phone_number: '+81' }
})(RegisterForm);


export default connect(mapStateToProps, { setMessage, clearMessage })(form);