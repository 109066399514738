import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import FA from 'react-fontawesome';

/**
 * ユニットローディングコンポーネント
 */
class pageLoading extends Component {

  // ローディングがセットされていれば表示する
  render() {
    // ローディングがセットされていない場合
    if (!this.props.loadingState.pageLoading) {
      return '';
    }
    return (
      <LoadWrap>
        <FA name="refresh" className="loading"/>
      </LoadWrap>
    );
  }
}


const LoadWrap = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    background-color: #FFF;
    display: flex;
    justify-content: center;
    align-items: center;

    .fa.fa-refresh {
      font-size: 14rem;

    }
`;

const mapStateToProps = state => {
  return { loadingState: state.loading };
};

export default connect(mapStateToProps)(pageLoading);

