import React, { Component } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';

/**
 * オーバーレイ
 */
class unitLoading extends Component {

  // オーバーレイがセットされていれば表示する
  render() {
    // オーバーレイがセットされていない場合
    if (!this.props.loadingState.overlay) {
      return '';
    }
    return (
      <Overlay className="page-overlay">
      </Overlay>
    );
  }
}


const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
`;

const mapStateToProps = state => {
  return { loadingState: state.loading };
};

export default connect(mapStateToProps)(unitLoading);

