import React, { Component } from 'react';
import FA from 'react-fontawesome';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';

class StatusInfoPopup extends Component {

  renderTooltip = (props) => {
    //  @see ...propsを渡さないのはredux-formにバグがあるため https://github.com/react-bootstrap/react-bootstrap/issues/3450
    return (
      <Popover className="large-popopver" arrowProps={props.arrowProps} placement={props.placement} style={props.style} ref={props.ref}>
        <div className="row mb-2">
          <small className="col-6 pr-2">UNCONFIRMED</small>
          <small className="col-6 pl-2">ログイン前</small>
        </div>
        <div className="row mb-2">
          <small className="col-6 pr-2">CONFIRMED</small>
          <small className="col-6 pl-2">確認済み</small>
        </div>
        <div className="row mb-2">
          <small className="col-6 pr-2">EXTERNAL_PROVIDER</small>
          <small className="col-6 pl-2">外部プロバイダーログイン</small>
        </div>
        <div className="row mb-2">
          <small className="col-6 pr-2">RESET_REQUIRED</small>
          <small className="col-6 pl-2">パスワードリセット中</small>
        </div>
        <div className="row mb-2">
          <small className="col-6 pr-2">FORCE_CHANGE_PASSWORD</small>
          <small className="col-6 pl-2">パスワード強制変更前</small>
        </div>
      </Popover>
    );
  }

  render() {
    return (
      <span>
        <OverlayTrigger placement="top" overlay={this.renderTooltip}>
          <Button className="ml-2 p-0 align-bottom" variant="link"><FA name="info-circle" /></Button>
        </OverlayTrigger>
      </span>
    );
  }
}


export default StatusInfoPopup;