import React, { Component } from 'react';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';
import { nl2br } from 'helpers';

/**
 * メッセージコンポーネント
 */
class Message extends Component {

  // メッセージがセットされていれば表示する
  render() {
    // メッセージがセットされていない場合
    if (!this.props.messageState.messages) {
      return '';
    }
    return (
      <div>
        {this.props.messageState.messages.map((data, index) => {
          return (
            <div key={index} >
              {data.type && data.text && <Alert variant={data.type} >{nl2br(data.text)}</Alert> }
            </div>
          );
        })}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { messageState: state.message };
};

export default connect(mapStateToProps)(Message);

