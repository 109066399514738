import React, { Component } from 'react';
import { Button, Navbar, Nav, NavDropdown } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { isAccountPage } from 'helpers';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { getHeader, clearHeader } from 'actions/headerAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { setMessage, clearMessage } from 'actions/messageAction';
import { Auth } from 'aws-amplify';
import { consoleLogger } from 'helpers';
import { history } from 'store/configureStore';
import { withAuthenticator } from 'aws-amplify-react';

class AuthedHeader extends Component {

  constructor(...props) {
    super(...props);

    // グローバルヘッダー情報確認
    // ヘッダー情報がなければAPIを叩いて取得する
    if (!this.props.headerState) {
      // ヘッダーが描画できないので画面全体をローディング状態にする
      this.props.setLoading('page');

      // APIを実行してグローバルステートをセット
      let email = '';

      if (this.props.authData.attributes && this.props.authData.attributes.email) {
        email = this.props.authData.attributes.email;
      }

      Auth.currentSession().then((currentSession) => { 
        this.props.getHeader(currentSession.idToken.jwtToken, email)
          .then(() => {
            this.props.clearLoading('page');

            // スタートページの場合はリダイレクト
            if (history.location.pathname === '/start') {
              if (this.props.headerState.isUser) {
                history.replace('/srv/users');
              } else {
                history.replace('/adm/accounts');
              }
            }
          })
          .catch(err => {
            this.props.setMessage('danger', `ヘッダー情報の取得に失敗しました。\n${err}`);
            this.props.clearLoading('page');
          });
      }).catch(e => {
        consoleLogger('token error', e);
        this.props.clearLoading('page');
      });
    } else if (history.location.pathname === '/start') {
      if (this.props.headerState.isUser) {
        history.replace('/srv/users');
      } else {
        history.replace('/adm/accounts');
      }
    }
  }

  signOut = () => {
    this.props.clearHeader();
    // 最初にグローバルサインアウト（発行されているトークン等を全て無効化）を実行する
    Auth.signOut({ global: true }).then(() => {
      history.replace('/logout');
    }).catch(e => {
      // @NOTE グローバルサインアウトは認証情報が必要で
      // 途中にパスワード変更リセットなどを行っていた場合は失敗するため、失敗した場合は通常サインアウトを行う
      consoleLogger('signOut error', e);
      Auth.signOut({ global: false }).then(() => {
        history.replace('/logout');
      }).catch(e => {
        consoleLogger('signOut error', e);
      });
    });
  }

  render() {
    return (
      <StyledNavbar bg="dark" expand="lg" className="navbar-dark " >
        <div className="navbar-brand">
          <div id="nav-logo">認証基盤管理システム</div>
        </div>
        {/* 管理者アカウント＋ユーザー両方可能 */}
        <Nav className="mr-auto">
          <NavDropdown className="activeMenu" title={ isAccountPage() ? '管理者アカウント' : 'ユーザー'}>
            {this.props.headerState && this.props.headerState.isAccount &&
              <NavDropdown.Item as={Link} to="/adm/accounts">管理者アカウント</NavDropdown.Item>}
            {this.props.headerState && this.props.headerState.isUser &&
              <NavDropdown.Item as={Link} to="/srv/users">ユーザー</NavDropdown.Item>}
          </NavDropdown>
        </Nav>
        <Nav>
          {this.props.headerState && 
          <NavDropdown title={this.props.headerState && this.props.headerState.mail ? this.props.headerState.mail : ''} className="dropdown-menu-right mailbox activeMail">
            <NavDropdown.Item onClick={this.signOut}>サインアウト</NavDropdown.Item>
          </NavDropdown>}
          {!this.props.headerState && 
            <Button className="p-0 text-white" variant="link" onClick={this.signOut}>サインアウト</Button>
          }
        </Nav>
      </StyledNavbar>
    );
  }
}

// プルダウンの文字色を白にする
export const StyledNavbar = styled(Navbar)`
  &.navbar-dark .navbar-nav .nav-link{
    color: #fff;
  }

  /* メール欄最小幅 */
  .mailbox {
    min-width: 160px;
  }
`;

const mapStateToProps = state => {
  return { headerState: state.header };
};

export default withAuthenticator(connect(mapStateToProps, { setMessage, clearMessage, getHeader, setLoading, clearLoading, clearHeader})(AuthedHeader));
