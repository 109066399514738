import React, { Component } from 'react';
import { Table, ButtonToolbar, Button } from 'react-bootstrap';
import SearchForm from './searchForm';
import StatusInfoPopup from './statusInfoPopup';
import { Link } from 'react-router-dom';
import { history } from 'store/configureStore';
import { setMessage, clearMessage } from 'actions/messageAction';
import { setLoading, clearLoading } from 'actions/loadingAction';
import { setPageData } from 'actions/payloadAction';
import UnitLoading from 'components/parts/loading/unitLoading';
import axios from 'axios';
import { consoleLogger } from 'helpers';
import { connect } from 'react-redux';
import Message from 'components/parts/message';
import { withAuthenticator } from 'aws-amplify-react';
import { validType } from 'const';
import client_config from 'client-config';
import { Auth } from 'aws-amplify';

class SrvUsers extends Component {

  componentWillUnmount() {
    this.cancelToken.cancel('unmounted component.');
  }

  constructor(...args) {
    super(...args);

    // メッセージ初期化（ただし他画面の遷移からのものでメッセージを残す場合はクリアしない（ユーザー削除時））
    if (!(history.location.state && history.location.state.keepMessage)) {
      this.props.clearMessage();
    }

    this.cancelToken = axios.CancelToken.source();

    if (!this.props.pageData.srvUsers || !this.props.pageData.srvUsers.service_list.length) {
      // ローディングスタート
      this.props.setLoading('unit');

      // initial local state
      this.state = {
        // page
        pagination: null,
        // data
        search_attr: 'email',
        search_str: '',
        search_service: 'virtual_all',
        list: [],
        loading: false,
        service_list: [],
      };

      // メッセージも初期化
      this.props.setMessage('info', '検索条件を入力してください。');

      // サービス一覧の取得を行う
      // 初期表示は行わない
      this.getService();
    } else {
      this.state = this.props.pageData.srvUsers;
      this.state.loading = false;

      // 管理者アカウント画面削除から帰ってきた場合の処理
      // 画面自体をリフレッシュすると数ページ読み込んでいた場合などに
      // 再読み込みが大変になるので対象データだけ抜き出す
      if (history.location.state && history.location.state.deleteUser) {
        this.pullUser(history.location.state.deleteUser);
      }
    }
  }

  // 一覧から対象のアカウントのみ抜き出す
  pullUser = (email) => {
    const list = this.state.list.filter((data) => {
      if (data.user.Attributes.email !== email) {
        return true;
      }
      return false;
    });

    // eslint-disable-next-line
    this.state.list = list; // この関数は初期マウント時のみ使用するためsetStateは使用しない
    this.props.setPageData('srvUsers', this.state);
  }

  submitData = (values) => {
    // 個別バリデーションを行うとボタン類が固まっていてエラーが見づらいのでここで実施する
    if (values.search_attr && values.search_str.length > 100) {
      this.props.setMessage('danger', '100文字以内で入力してください。');

      return false;
    }

    // @NOTE 検索時に前のデータが残るということがあり、念のためページネーションをオフ
    values.pagination = null;

    this.getData(values);
  }

  loadMore = () => {
    const values = {
      search_attr: this.state.search_attr,
      search_str: this.state.search_str,
      search_service: this.state.search_service,
      pagination: this.state.pagination
    };

    consoleLogger(this.state);
    this.getData(values);
  }

  getService = async () => {
    const url = '/auth/srv/services';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        // this.setState({ loading: false });
        if (res.status === 200) {
          const setData = {
            service_list: [],
          };
          if (!res.data.items || !res.data.items.length) {
            // データなし
            this.props.setMessage('info', 'サービスは見つかりませんでした。');
            this.setState({ service_list: setData['service_list'] });
          } else {

            setData['service_list'] = res.data.items;
            consoleLogger('StatusCode ' + url, res.data);
            this.setState({ service_list: setData['service_list'] });
          }
        } else {
          this.props.setMessage('danger', `サービスの取得に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('StatusCode ' + url, res.data);
        }
        this.props.setPageData('srvUsers', this.state);  // 一覧系は別ページ遷移でもデータを残す
        this.props.clearLoading('unit');
      })
      .catch(err => {
        this.setState({ loading: false });
        this.props.clearLoading('unit');

        // エラー時、 ローディングクリア＋ログ出力
        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }
        this.props.setMessage('danger', `サービスの取得に失敗しました。再実行してください。\n${err}`);
        this.props.setPageData('srvUsers', this.state);  // 一覧系は別ページ遷移でもデータを残す
        // this.props.clearLoading('unit');
        consoleLogger('Catch ' + url, err);
      });
  };

  getData = async (values, initial=false) => {

    if (!initial) {
      // 初回はローディングユニット出しているので不要
      this.setState({ loading: true });
    }

    const data = {
      search_attr: values.search_attr,
      search_str: values.search_str,
      search_service: values.search_service,
    };

    // ページネーションがある場合のみ続きのページを読み込む
    if (values.pagination) {
      data['pagination'] = this.state.pagination;
    }
    consoleLogger(data);

    const url = '/auth/srv/users';
    const currentSession = await Auth.currentSession();
    const requestData = {
      method: 'get', url: client_config['api_url'] + url,
      params: data,
      headers: { 'Authorization': currentSession.idToken.jwtToken },
      cancelToken: this.cancelToken.token
    };
    return axios(requestData)
      .then(res => {
        consoleLogger('API GET:' + url);
        consoleLogger(res);
        this.setState({ loading: false });
        if (res.status === 200) {
          const setData = {
            list: [],
            pagination: null,
            search_attr: values.search_attr,
            search_str: values.search_str,
            search_service: values.search_service,
          };
          if (!values.pagination && (!res.data.items || !res.data.items.length) && !res.data.pagination) {
            // ページャーもデータもなし
            if (!values.pagination) {
              this.props.setMessage('info', 'ユーザーは見つかりませんでした。');
            } else {
              setData['list'] = this.state.list;
            }

            this.setState(setData);
          } else {
            // ステートにセット

            let list = [];
            if (!values.pagination) {
              list = res.data.items;
            } else {
              // ページャーがある場合のみデータを繋げる
              list = this.state.list.concat(res.data.items);
            }
            setData['list'] = list;

            if (res.data.pagination) {
              setData['pagination'] = res.data.pagination;
            }

            this.setState(setData);
            this.props.clearMessage();

          }
        } else {
          this.props.setMessage('danger', `ユーザー一覧の取得に失敗しました。\n${res.data}`);
          consoleLogger('StatusCode ' + url, res.data);
        }
        this.forceUpdate();
        this.props.setPageData('srvUsers', this.state);  // 一覧系は別ページ遷移でもデータを残す
        this.props.clearLoading('unit');
      })
      .catch(err => {
        // エラー時、 ローディングクリア＋ログ出力
        this.setState({ loading: false });
        this.props.setPageData('srvUsers', this.state);  // 一覧系は別ページ遷移でもデータを残す

        if (err.constructor && err.constructor.name === 'Cancel') {
          return false;
        }

        this.props.setMessage('danger', `ユーザー一覧の取得に失敗しました。\n${err}`);
        this.props.clearLoading('unit');
        consoleLogger('Catch ' + url, err);
      });
  };

  render() {
    return (
      <div className="col-10 pt-3 pb-3">
        <UnitLoading />
        {this.state.service_list.length > 0 && <SearchForm onSubmit={this.submitData} initial={this.state} loadingState={this.state.loading} serviceList={this.state.service_list} />}
        <Message />
        {this.state.list.length > 0 && <Table striped bordered hover>
          <thead>
            <tr>
              <th>sub（ユーザー識別子）</th>
              <th>Eメール</th>
              <th>サービス</th>
              <th>有効状態</th>
              <th>
                ステータス
                <StatusInfoPopup />
              </th>
            </tr>
          </thead>
          <tbody>
            {this.state.list.map((data, index) => {
              const user = data.user;
              const service = data.service;
              return (
                <tr key={ index }>
                  <td><Link to={'/srv/users/' + user.Attributes.sub} >{user.Attributes.sub}</Link></td>
                  <td>{user.Attributes.email}</td>
                  <td>{service.service_name}</td>
                  <td>{validType[user.Enabled]}</td>
                  <td>{user.UserStatus}</td>
                </tr>
              );
            })}
          </tbody>
        </Table>}
        {this.state.pagination && <ButtonToolbar className="justify-content-center">
          {this.state.pagination && <Button disabled={this.state.loading} onClick={this.loadMore}>
            ユーザーをさらにロード
          </Button>}
        </ButtonToolbar>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return { pageData: state.payload };
};

export default withAuthenticator(connect(mapStateToProps, { setPageData, setMessage, clearMessage, setLoading, clearLoading})(SrvUsers));


