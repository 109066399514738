import { actions } from 'actions';

/**
 * アプリケーション内で保持するページデータのセット
 * 
 * @param string pageName ページ名
 * @param object data 保存するデータ
 */
export const setPageData = (pageName, data) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_PAGEDATA,
      pageName: pageName,
      pageData: data,
    });
  };
};
