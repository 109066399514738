import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`


/* ここにページ共通のCSSを記載する */
.form-control.no-border-radius, .btn.no-border-radius {
  border-radius: 0;
}


/* 大き目のポップオーバー
bootstrapのjsが出すものなのでCSSで対応 */
.large-popopver {
  max-width: 340px;
  width: 340px;
}

/* パンくずリストのセパレーターは>とのこと */
.breadcrumb-item+.breadcrumb-item::before {
  content: ">";
}

/* 統一ボタン長さ */
.def-btn-long {
  width: 7em;
}

.def-btn-more-long {
  width: 11em;
}

/* テキストエリア */
textarea.form-control.row-3 {
  height: 90px;
}

/* 外線直通電話番号入力エリア */
.input-tel {
  width: 170px;
}

td {
  word-break: break-all;
}

input[type="radio"],input[type="radio"]+label,input[type="checkbox"],input[type="checkbox"]+label {
  cursor: pointer;
}

.page-item {
  cursor: pointer;

  &.disabled,&.active {
    cursor: default;
  }
}

/* よく使用するCSS */
.w10p {
  width: 10%;
}
.w20p {
  width: 20%;
}
.w30p {
  width: 30%;
}
.w40p {
  width: 40%;
}
.w50p {
  width: 50%;
}
.w60p {
  width: 60%;
}
.w70p {
  width: 70%;
}
.w80p {
  width: 80%;
}
.w90p {
  width: 90%;
}

/* 全体的にAWSコンソールに寄せる */
.bg-dark.navbar {
  background-color: #232F3E!important;
  height: 40px;
}

.bg-gray {
  background-color: #F5F7F7;
  min-height: 100vh;
}

.breadcrumb {
  background-color: #F5F7F7;
  border-bottom: 1px solid #ccc;
}

table,dl,.btn-outline-secondary,ul {
  background-color: #FFF;
}

.pagination {
  background: none;
  float: right;
}

/* LG固定用 */
.navbar-expand-lg {
    -ms-flex-flow: row nowrap;
    flex-flow: row nowrap;
    -ms-flex-pack: start;
    justify-content: flex-start;
}

/* bootstrapの追加クラス */
.rounded-top-left {
  border-top-left-radius: .25rem !important;
}

.rounded-top-right {
  border-top-right-radius: .25rem !important;
}

.rounded-bottom-right {
  border-bottom-right-radius: .25rem !important;
}

.rounded-bottom-left {
  border-bottom-left-radius: .25rem !important;
}

.fade,
.modal.fade .modal-dialog {
  transition-duration: .025s !important;
}

/* ローディング */
.fa.loading {
  animation: spin 2s linear infinite;
}
@keyframes spin {
	0% {transform: rotate(0deg);}
	100% {transform: rotate(360deg);}
}

`;

