import { actions } from 'actions';

/**
 * パンくずセットアクション
 * 
 * @param string text 表示メッセージ
 */
export const setBreadcrumb = (text) => {
  return (dispatch) => {
    dispatch({
      type: actions.SET_BREADCRUMB,
      breadcrumb: text,
    });
  };
};


/**
 * パンくずクリアーアクション
 * 
 */
export const clearBreadcrumb = () => {
  return (dispatch) => {
    dispatch({
      type: actions.CLEAR_BREADCRUMB,
      breadcrumb: '',
    });
  };
};