import React, { Component } from 'react';
import RegisterForm from './registerForm';
import axios from 'axios';
import { consoleLogger } from 'helpers';
import { setMessage, clearMessage } from 'actions/messageAction';
import { connect } from 'react-redux';
import client_config from 'client-config';

class PriForms extends Component {

  constructor(...args) {
    super(...args);
    this.state = {
      submited: false,
    };
    this.props.clearMessage();
  }

  // ここはajax通信にする
  submitData = (values) => {
    if (!values.srv_chk && !values.adm_chk) {
      this.props.setMessage('danger', '申請する利用権限を設定してください。');
      return false;
    }

    // 送るデータの作成
    const data = {
      name: values.name,
      preferred_username: values.preferred_username,
      email: values.email,
      phone_number: values.phone_number,
      srv_chk: values.srv_chk ? '1' : '0',
      adm_chk: values.adm_chk ? '1' : '0',
      remarks: values.remarks,
    };

    const url = '/auth/pri/forms';
    const requestData = {
      method: 'post',
      url: client_config['api_url'] + url,
      data: data,
    };

    return axios(requestData)
      .then(res => {
        consoleLogger('API POST:' + url);
        consoleLogger(res);
        if (res.status === 200 && !res.data.errcode) {
          // 成功時
          this.setState({
            submited: true,
          });
          this.props.setMessage('success', '利用申請が完了しました。\n申請の承認には数日かかります。承認された場合のみメールでログイン情報をお送りします。\n【ご注意】\n・申請が却下された場合は連絡メールは届きません。5営業日が過ぎても連絡がない場合は却下されています。\n・メールはjunkフォルダーに振り分けられる場合もございますので、同フォルダーへの連絡にもご注意ください。\n（ブラウザタブを閉じてください。）');
        } else {
          // その他のエラー
          this.props.setMessage('danger', `利用申請の登録に失敗しました。再実行してください。\n${res.data}`);
          consoleLogger('other error:' + url , res.data.errcode);
        }
      })
      .catch((err) => {
        const res = err.response;

        if (res && res.status === 400 && res.data.errcode === 'EMAIL_ALREADY_EXISTS') {
          this.props.setMessage('warning', 'メールアドレスは申請済です。決裁をお待ちください。');
        } else if (res && res.status === 400 && res.data.errcode === 'EMAIL_ALREADY_REGISTERED') {
          this.props.setMessage('warning', 'メールアドレスは登録済です。');
        } else if (res && res.status === 400 && res.data.errcode === 'INVALID_PARAMETER_PHONE_NUMBER') {
          this.props.setMessage('warning', '正しい電話番号を入力してください。');
        } else if (res && res.status === 400) {
          this.props.setMessage('warning', 'バリデーションエラーが発生しました。');
        } else {
          // その他のエラー
          this.props.setMessage('danger', `利用申請の登録に失敗しました。再実行してください。\n${err}`);
          consoleLogger('catch error:' + url, err);
          if (res) {
            consoleLogger('catch error:' + url, res.data.errcode);
          }
        }
      });
  }

  render() {
    return (
      <div className="col-12 pr-0 pl-0 pb-4 bg-gray">
        <RegisterForm onSubmit={this.submitData} message={this.state.message} submited={this.state.submited}/>
      </div>
    );
  }

}

const mapStateToProps = () => {
  return {};
};

export default connect(mapStateToProps, {setMessage, clearMessage})(PriForms);
