import { applyMiddleware, createStore, compose } from 'redux';
import createRootReducer from 'reducers';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';

export const history = createBrowserHistory();

export default function (preloadedState) {
  const middleware = [
    thunk,
    routerMiddleware(history)
  ];

  // redux-logger を production 環境以外の場合に有効化する
  if (process.env.NODE_ENV !== 'production') {
    const { logger } = require('redux-logger');
    middleware.push(logger);
  }

  const enhancers = [];
  if (typeof window.devToolsExtension === 'function') {
    enhancers.push(window.devToolsExtension());
  }

  const store = createStore(
    createRootReducer(history),
    preloadedState,
    compose(applyMiddleware(...middleware), ...enhancers)
  );
  return store;
}
