import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import configureStore from 'store/configureStore';
import * as serviceWorker from 'registerServiceWorker';

import { GlobalStyle } from 'styles/global';

import PageLoading from 'components/parts/loading/pageLoading';
import Overlay from 'components/parts/loading/overlay';
import Router from 'components/router';

import { consoleLogger } from 'helpers';

/* for aws amplify */
import client_config from 'client-config';
import { GetAmplifyConfig } from 'aws-config';
import Amplify from 'aws-amplify';

/* for babel-polyfill */
import "core-js/stable";
import "regenerator-runtime/runtime";
import "es6-promise/auto";
import "fetch-polyfill";

// ストアの生成
const store = configureStore();

ReactDOM.render(
  <Provider store={store}>
    <Router />
    <GlobalStyle />
    <PageLoading />
    <Overlay />
  </Provider>,
  document.getElementById('react-root')
);

// Authの構築。
try {
  Amplify.configure({
    Auth: GetAmplifyConfig(client_config['client_id']),
    Analytics: { disabled: true }
  });

} catch (err) {
  consoleLogger('index-Amplify.configure-catch', err);
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();