import { actions } from 'actions';

/**
 * ローディングセットアクション
 * 
 * @param type button|unit|page
 */
export const setLoading = (type = 'unit', buttonName = '') => {
  return (dispatch) => {
    switch (type) {
      case 'button':
        dispatch({
          type: actions.SET_BUTTON_LOADING,
          buttonName: buttonName,
        });
        break;
      case 'page':
        dispatch({
          type: actions.SET_PAGE_LOADING,
        });
        break;
      case 'overlay':
        dispatch({
          type: actions.SET_OVERLAY,
        });
        break;
      case 'unit':
      default:
        dispatch({
          type: actions.SET_UNIT_LOADING,
        });
        break;
    }
  };
};


/**
 * ローディングクリアーアクション
 * 
  * @param type button|unit|page
 */
export const clearLoading = (type = 'unit', buttonName = '') => {
  return (dispatch) => {
    switch (type) {
      case 'button':
        dispatch({
          type: actions.CLEAR_BUTTON_LOADING,
          buttonName: buttonName,
        });
        break;
      case 'page':
        dispatch({
          type: actions.CLEAR_PAGE_LOADING,
        });
        break;
      case 'overlay':
        dispatch({
          type: actions.CLEAR_OVERLAY,
        });
        break;
      case 'unit':
      default:
        dispatch({
          type: actions.CLEAR_UNIT_LOADING,
        });
        break;
    }
  };
};