import React from 'react';
import { Field, reduxForm } from 'redux-form';
import { Form, Button } from 'react-bootstrap';
import * as style from 'styles/util';
import Check from 'components/parts/form/check';
import { Alert } from 'react-bootstrap';
import { connect } from 'react-redux';

const DecideModalForm = props => {
  const { handleSubmit, submitting, error } = props;
  return (
    <Form onSubmit={handleSubmit}>
      <div className="col-10 offset-1">
        <p>申請に対する処理を以下から選択してください。<style.Req>（必須）</style.Req></p>
        <Form.Group className="pl-4">
          <Field
            name="form_id"
            component="input"
            type="hidden"
          />
          <Field
            name="type"
            label="申請を承認する（申請者にメールが送信されます）"
            component={Check}
            type="radio"
            value="1"
            id="dec-form-radio-1"
            feedback="off"
          />
          <div className="mb-2">
            <span className="pl-4 pr-4 type-title">権限設定</span>
            <Field
              name="srv_chk"
              label="ユーザー管理権限"
              component={Check}
              type="checkbox"
              value="1"
              id="dec-form-check-1"
              feedback="off"
              inline="on"
            />
            <Field
              name="adm_chk"
              label="管理者アカウント管理権限"
              component={Check}
              type="checkbox"
              value="1"
              id="dec-form-check-2"
              feedback="on"
              inline="on"
            />
          </div>
          <Field
            name="type"
            label="申請を却下する（申請者にメールは送信されません）"
            component={Check}
            type="radio"
            value="2"
            id="dec-form-radio-2"
            feedback="off"
          />
        </Form.Group>
        {error && <Alert variant="danger" >{error}</Alert>}
        <div className="text-center mt-4">
          <Button variant="primary" disabled={submitting} type="submit" className="def-btn-long submit">決裁</Button>
        </div>
      </div>
    </Form>
  );
};


const mapStateToProps = (state, props) => ({
  initialValues: {
    form_id: props.data.form_id,
    srv_chk: JSON.parse(props.data.form).srv_chk === '1' ? '1' : '',
    adm_chk: JSON.parse(props.data.form).adm_chk === '1' ? '1' : '',
  },
});

export default connect(
  mapStateToProps
)(reduxForm({
  form: '_DecideModalForm',
})(DecideModalForm));
